import { useState, useEffect } from "react"

import { Grid, FormControl, Typography } from "@mui/material";
import CustomButton from "../../../forms/button/CustomButton";
import { useModal } from "../../../context/ModalContext";
import { formatDate } from "../../../utils/Helper";
import FleetDetailCardComponent from "./FleetDetailCardComponent";
import { useSelector } from "react-redux";
import InspectionsModal from "./detailModal/InspectionsModal";

const InspectionsCardComponent = () => {
  
  const [inspectionsDetails, setInspectionsDetails] = useState(null)
  const fleet = useSelector((state) => state.fleet);
  const { openModal } = useModal();

  useEffect(() => {

    const inspectionItems = {
      altimeter_due_date: formatDate(fleet?.inspections?.[0]?.altimeter_due_date),
      transponder_due_date: formatDate(fleet?.inspections?.[0]?.transponder_due_date),
      pitot_static_due_date: formatDate(fleet?.inspections?.[0]?.pitot_static_due_date),
      vor_check_due_date: formatDate(fleet?.inspections?.[0]?.vor_check_due_date),
      hundred_hour_due: fleet?.inspections?.[0]?.hundred_hour_due,
      annual_due_date: formatDate(fleet?.inspections?.[0]?.annual_due_date),
      elt_battery_replacement_date: formatDate(
        fleet?.inspections?.[0]?.elt_inspections?.elt_battery_replacement_date
      ),
      elt_inspection_date: formatDate(fleet?.inspections?.[0]?.elt_inspections?.elt_inspection_date),
      elt_acr_artex_date: formatDate(fleet?.inspections?.[0]?.elt_inspections?.elt_acr_artex_date),
    };

    setInspectionsDetails(inspectionItems);
  
  }, [fleet])


  return (
  <>
    <FleetDetailCardComponent
      title="Inspections"
      action={
        <CustomButton
          label="Edit"
          size="medium"
          onClick={() => openModal("Inspections")}
        />
      }
      component={
        <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">Altimeter Due Date</Typography>
                <Typography paragraph>{inspectionsDetails?.altimeter_due_date || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">Transponder Due Date</Typography>
                <Typography paragraph>{inspectionsDetails?.transponder_due_date || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">Pitot Static Due Date</Typography>
                <Typography paragraph>{inspectionsDetails?.pitot_static_due_date || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">Vor Check Due Date</Typography>
                <Typography paragraph>{inspectionsDetails?.vor_check_due_date || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">Hundred Hour Due</Typography>
                <Typography paragraph>{inspectionsDetails?.hundred_hour_due || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">Annual Due Date</Typography>
                <Typography paragraph>{inspectionsDetails?.annual_due_date || "-"}</Typography>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">ELT Battery Replacement Date</Typography>
                <Typography paragraph>{inspectionsDetails?.elt_battery_replacement_date || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">ELT Inspection Date</Typography>
                <Typography paragraph>{inspectionsDetails?.elt_inspection_date || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <Typography variant="subtitle1">ELT ACR Artex Date</Typography>
                <Typography paragraph>{inspectionsDetails?.elt_acr_artex_date || "-"}</Typography>
              </FormControl>
            </Grid>
        </Grid>
      }
    />
    <InspectionsModal inspectionsDetails={inspectionsDetails} setInspectionsDetails={setInspectionsDetails} tailNumber={fleet?.details?.tail_number}/>
   </>
  );
};

export default InspectionsCardComponent;
