import React, { useEffect } from "react";
import { useFormik, FormikProvider, Form } from "formik";
import { Grid } from "@mui/material";
import Modal from "../../../modal/Modal";
import CustomDatePicker from "../../../../forms/datePicker/CustomDatePicker";
import CustomInput from "../../../../forms/input/CustomInput";
import CustomButton from "../../../../forms/button/CustomButton";
import { useModal } from "../../../../context/ModalContext";
import { useLoader } from "../../../../context/LoaderContext";
import { useToast } from "../../../../context/ToastContext";
import { fetchPUTRequest } from "../../../../utils/Services";
import { formatDate } from "../../../../utils/Helper";
import { useSelector, useDispatch } from "react-redux";
import { setInspections } from "../../../../store/features/FleetSlice";

const InspectionsModal = ({ inspectionsDetails, tailNumber }) => {
  const { isModal, closeModal } = useModal();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const fleet = useSelector((state) => state.fleet);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);

  const { setToast } = useToast();

  const formik = useFormik({
    initialValues: {
      tail_number: tailNumber,
      altimeter_due_date: inspectionsDetails?.altimeter_due_date || "",
      transponder_due_date: inspectionsDetails?.transponder_due_date || "",
      pitot_static_due_date: inspectionsDetails?.pitot_static_due_date || "",
      vor_check_due_date: inspectionsDetails?.vor_check_due_date || "",
      hundred_hour_due: inspectionsDetails?.hundred_hour_due || "",
      annual_due_date: inspectionsDetails?.annual_due_date || "",
      elt_battery_replacement_date:
        inspectionsDetails?.elt_battery_replacement_date || "",
      elt_inspection_date: inspectionsDetails?.elt_inspection_date || "",
      elt_acr_artex_date: inspectionsDetails?.elt_acr_artex_date || "",
    },
    onSubmit: async (values) => {
      const newPayload = { ...values };

      const mainPayload = {
        ...newPayload,
        elt_inspections: {
          elt_battery_replacement_date: newPayload.elt_battery_replacement_date,
          elt_inspection_date: newPayload.elt_inspection_date,
          elt_acr_artex_date: newPayload.elt_acr_artex_date,
        },
      };

      delete mainPayload.elt_battery_replacement_date;
      delete mainPayload.elt_inspection_date;
      delete mainPayload.elt_acr_artex_date;

      startLoading();

      let apiUrl = `/fleet/member/update-plane-inspections`;
      if (user?.role === "owner") {
        apiUrl = `/fleet/owner/update-plane-inspections`;
      } else if (user?.role === "admin") {
        apiUrl = `/fleet/admin/update-plane-inspections`;
      }

      const response = await fetchPUTRequest(apiUrl, mainPayload);

      if (response?.statusCode === 200 && response) {
        setToast({
          open: true,
          message: response?.Message,
          severity: "success",
        });
        closeModal();

        const newObj = { ...fleet?.inspections[0] };

        Object.assign(newObj, response?.data);

        dispatch(setInspections([newObj]));
        stopLoading();
      } else {
        setToast({
          open: true,
          message: response?.Message,
          severity: "error",
        });
        stopLoading();
        formik.resetForm();
        closeModal();
      }
    },
  });

  useEffect(() => {
    if (inspectionsDetails) {
      formik.setFieldValue(
        "altimeter_due_date",
        formatDate(inspectionsDetails.altimeter_due_date)
      );
      formik.setFieldValue(
        "transponder_due_date",
        formatDate(inspectionsDetails.transponder_due_date)
      );
      formik.setFieldValue(
        "pitot_static_due_date",
        formatDate(inspectionsDetails.pitot_static_due_date)
      );
      formik.setFieldValue(
        "vor_check_due_date",
        formatDate(inspectionsDetails.vor_check_due_date)
      );
      formik.setFieldValue(
        "hundred_hour_due",
        inspectionsDetails.hundred_hour_due
      );
      formik.setFieldValue(
        "annual_due_date",
        formatDate(inspectionsDetails.annual_due_date)
      );
      formik.setFieldValue(
        "elt_battery_replacement_date",
        formatDate(inspectionsDetails?.elt_battery_replacement_date)
      );
      formik.setFieldValue(
        "elt_inspection_date",
        formatDate(inspectionsDetails?.elt_inspection_date)
      );
      formik.setFieldValue(
        "elt_acr_artex_date",
        formatDate(inspectionsDetails?.elt_acr_artex_date)
      );
    }
  }, [inspectionsDetails]);

  return (
    <Modal
      title="Update Inspections"
      open={isModal.open && isModal.type === "Inspections"}
      content={
        <FormikProvider value={formik}>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="altimeter_due_date"
                  label="Altimeter Due Date"
                  value={formik.values.altimeter_due_date}
                  onChange={(value) =>
                    formik.setFieldValue("altimeter_due_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="transponder_due_date"
                  label="Transponder Due Date"
                  value={formik.values.transponder_due_date}
                  onChange={(value) =>
                    formik.setFieldValue("transponder_due_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="pitot_static_due_date"
                  label="Pitot Static Due Date"
                  value={formik.values.pitot_static_due_date}
                  onChange={(value) =>
                    formik.setFieldValue("pitot_static_due_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="vor_check_due_date"
                  label="VOR Check Due Date"
                  value={formik.values.vor_check_due_date}
                  onChange={(value) =>
                    formik.setFieldValue("vor_check_due_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  label="Hundred Hour Due"
                  type="number"
                  name="hundred_hour_due"
                  value={formik.values.hundred_hour_due}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="annual_due_date"
                  label="Annual Due Date"
                  value={formik.values.annual_due_date}
                  onChange={(value) =>
                    formik.setFieldValue("annual_due_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="elt_battery_replacement_date"
                  label="ELT Battery Replacement Date"
                  value={formik.values.elt_battery_replacement_date}
                  onChange={(value) =>
                    formik.setFieldValue("elt_battery_replacement_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="elt_inspection_date"
                  label="ELT Inspection Date"
                  value={formik.values.elt_inspection_date}
                  onChange={(value) =>
                    formik.setFieldValue("elt_inspection_date", value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  name="elt_acr_artex_date"
                  label="ELT ACR Artex Date"
                  value={formik.values.elt_acr_artex_date}
                  onChange={(value) =>
                    formik.setFieldValue("elt_acr_artex_date", value)
                  }
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      }
      action={
        <CustomButton
          label="Update"
          size="medium"
          width="fit-content"
          disabled={false}
          bgColor="#479DE1"
          isLoading={isLoading}
          onClick={formik.handleSubmit}
        />
      }
    />
  );
};

export default InspectionsModal;
