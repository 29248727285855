import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGETRequest, fetchPOSTRequest } from "../../utils/Services";

import { format, parse } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import { setTax } from "../../store/features/BusinessSlice";
import {
  setCustomerDetails,
  setIsSegmentConfirmed,
  setLoading,
  setOfferPriceResponse,
  setQuotedPrice,
  setSearchCompleted,
  setSearchTripDetails,
  setSelectedPlaneDetails,
  setTotalNumberOfPassengers,
} from "../../store/features/CreateBookingSlice";
import AddDestinationComponent from "../destinations/AddDestinationComponent";

const SearchBookingComponent = () => {
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [openAddDestination, setOpenAddDestination] = useState(false);
  const [destinationType, setDestinationType] = useState("");

  // customer details

  const dispatch = useDispatch();
  const [destinations, setDestinations] = useState([]);
  const {
    tripType,
    departureAirport,
    arrivalAirport,
    departureDate,
    departureTime,
    returnDate,
    returnTime,
    loading,
  } = useSelector((state) => state.createBooking.searchTripDetails);

  const selectedPlaneDetails = useSelector(
    (state) => state.createBooking.selectedPlaneDetails
  );

  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    const fetchDestinations = async () => {
      let endpoint = `/destination/member/get-destinations`;
      if (role === "owner") {
        endpoint = `/destination/owner/get-destinations`;
      } else if (role === "admin") {
        endpoint = `/destination/admin/get-destinations`;
      }

      const response = await fetchGETRequest(endpoint);
      if (response?.statusCode === 200 && response.destinations) {
        const sortedDestinations = response.destinations
          .slice()
          .sort((a, b) => a.airport_name.localeCompare(b.airport_name));
        setDestinations(sortedDestinations);
      }
    };
    fetchDestinations();
  }, [role]);

  const handleSearch = async () => {
    dispatch(setLoading(true));

    // Reset other state fields to their default values
    dispatch(setOfferPriceResponse({}));
    dispatch(
      setSelectedPlaneDetails({
        selectedPlaneTailNumber: "",
        available: false,
        passengerTotalFlightDistance: 0,
        passengerTotalFlightDuration: 0,
        crewTotalFlightDistance: 0,
        crewTotalFlightDuration: 0,
        tripBasePrice: 0,
        tripTax: 0,
        baseAmountAtTimeOfBooking: 0,
        taxAtTimeOfBooking: 0,
        totalDueAtTimeOfBooking: 0,
        baseAmountDueLater: 0,
        baseAmountDueLaterDate: null,
        taxDueLater: 0,
        totalDueLater: 0,
        flight_segments: [],
      })
    );
    dispatch(
      setQuotedPrice({
        tripBasePrice: 0,
        tripTax: 0,
        baseAmountAtTimeOfBooking: 0,
        taxAtTimeOfBooking: 0,
        totalDueAtTimeOfBooking: 0,
        baseAmountDueLater: 0,
        baseAmountDueLaterDate: null,
        taxDueLater: 0,
        totalDueLater: 0,
        flight_segments: [],
      })
    );
    dispatch(
      setCustomerDetails({
        customerId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneCountryCode: "",
        phoneNumber: "",
        nationality: "",
        passportNumber: "",
        passportExpiration: "",
        gender: "",
      })
    );
    dispatch(setTotalNumberOfPassengers(1));
    dispatch(setIsSegmentConfirmed(false));
    dispatch(setSearchCompleted(false));

    // Update search trip details with the new values
    dispatch(
      setSearchTripDetails({
        tripType,
        departureAirport,
        arrivalAirport,
        departureDate,
        departureTime,
        returnDate,
        returnTime,
      })
    );

    // Convert departure and return times to destination timezone using date-fns-tz
    const departureDestination = destinations.find(
      (dest) => dest.airport_code === departureAirport
    );
    const arrivalDestination = destinations.find(
      (dest) => dest.airport_code === arrivalAirport
    );

    const departureTimezone = departureDestination?.airport_timezone || "UTC";
    const arrivalTimezone = arrivalDestination?.airport_timezone || "UTC";

    const departureDateTime = zonedTimeToUtc(
      parse(
        `${format(departureDate, "yyyy-MM-dd")} ${departureTime}`,
        "yyyy-MM-dd hh:mm a",
        new Date()
      ),
      departureTimezone
    ).toISOString();

    let arrivalDateTime = null;
    if (tripType === "round_trip" && returnDate && returnTime) {
      arrivalDateTime = zonedTimeToUtc(
        parse(
          `${format(returnDate, "yyyy-MM-dd")} ${returnTime}`,
          "yyyy-MM-dd hh:mm a",
          new Date()
        ),
        arrivalTimezone
      ).toISOString();
    }

    const payload = {
      trip_type: tripType,
      flight_segments: [
        {
          departure_airport_code: departureAirport,
          arrival_airport_code: arrivalAirport,
          departing_flight_time: departureDateTime.toString(),
          trip_leg_number: 1,
        },
      ],
    };

    if (arrivalDateTime) {
      payload.flight_segments.push({
        departure_airport_code: arrivalAirport,
        arrival_airport_code: departureAirport,
        departing_flight_time: arrivalDateTime.toString(),
        trip_leg_number: 2,
      });
    }

    // Log the exact time being sent in the payload
    payload.flight_segments.forEach((segment, index) => {
      console.log(
        `Payload Segment ${index + 1} Departure Time:`,
        segment.departing_flight_time
      );
    });

    try {
      let endpoint = `/booking/member/get-booking-prices`;
      if (role === "owner") {
        endpoint = `/booking/owner/get-booking-prices`;
      } else if (role === "admin") {
        endpoint = `/booking/admin/get-booking-prices`;
      }

      const response = await fetchPOSTRequest(endpoint, payload);
      if (response && response.data) {
        dispatch(setOfferPriceResponse(response.data.priceDetails));
        dispatch(setTax(response.data.taxSettings.tax_rate));
        dispatch(setSearchCompleted(true));
      }
    } catch (error) {
      console.error("Error fetching booking price:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOpenAddDestinationModal = (type) => {
    setDestinationType(type);
    setOpenAddDestination(true);
  };

  const handleCloseAddDestinationModal = async (newDestination) => {
    setOpenAddDestination(false);

    // Refetch destinations after adding a new one
    let apiUrl = `/destination/member/get-destinations`;
    if (role === "owner") {
      apiUrl = `/destination/owner/get-destinations`;
    } else if (role === "admin") {
      apiUrl = `/destination/admin/get-destinations`;
    }

    const response = await fetchGETRequest(apiUrl);

    if (response?.statusCode === 200 && response.destinations) {
      setDestinations(response.destinations);
    }

    if (newDestination) {
      if (destinationType === "departure") {
        dispatch(
          setSearchTripDetails({
            departureAirport: newDestination.airport_code,
          })
        );
      } else if (destinationType === "arrival") {
        dispatch(
          setSearchTripDetails({ arrivalAirport: newDestination.airport_code })
        );
      }
    }

    setDestinationType("");
  };

  const isFormValid =
    departureAirport &&
    arrivalAirport &&
    departureDate &&
    departureTime &&
    (tripType === "one_way" || (returnDate && returnTime));

  const timeOptions = Array.from({ length: 24 }, (_, hour) => {
    const period = hour < 12 ? "AM" : "PM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour.toString().padStart(2, "0")}:00 ${period}`;
  });

  return (
    <>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sm={4} mb={5}>
          <TextField
            select
            label="Trip Type"
            value={tripType}
            onChange={(e) =>
              dispatch(setSearchTripDetails({ tripType: e.target.value }))
            }
            fullWidth
          >
            <MenuItem value="one_way">One Way</MenuItem>
            <MenuItem value="round_trip">Round Trip</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sm={6} md={5} mb={5}>
          <TextField
            select
            label="Select Departure"
            value={departureAirport}
            onChange={(e) =>
              dispatch(
                setSearchTripDetails({ departureAirport: e.target.value })
              )
            }
            fullWidth
          >
            <MenuItem value="">Select Departure Airport</MenuItem>
            {Array.isArray(destinations) &&
              destinations.map((dest, index) => (
                <MenuItem key={index} value={dest.airport_code}>
                  {dest.airport_code} - {dest.airport_name}
                </MenuItem>
              ))}
            <MenuItem
              value="+add"
              onClick={() => handleOpenAddDestinationModal("arrival")}
            >
              + Add a Destination
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={5} mb={5}>
          <TextField
            select
            label="Select Arrival"
            value={arrivalAirport}
            onChange={(e) =>
              dispatch(setSearchTripDetails({ arrivalAirport: e.target.value }))
            }
            fullWidth
          >
            <MenuItem value="">Select Arrival Airport</MenuItem>
            {Array.isArray(destinations) &&
              destinations.map((dest, index) => (
                <MenuItem key={index} value={dest.airport_code}>
                  {dest.airport_code} - {dest.airport_name}
                </MenuItem>
              ))}
            <MenuItem
              value="+add"
              onClick={() => handleOpenAddDestinationModal("arrival")}
            >
              + Add a Destination
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sm={6} md={5} mb={5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select Departure Date"
              value={departureDate}
              onChange={(newValue) =>
                dispatch(setSearchTripDetails({ departureDate: newValue }))
              }
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={new Date()}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={5} mb={5}>
          <TextField
            select
            label="Select Departure Time"
            value={departureTime}
            onChange={(e) =>
              dispatch(setSearchTripDetails({ departureTime: e.target.value }))
            }
            fullWidth
          >
            <MenuItem value="">Select Departure Time</MenuItem>
            {timeOptions.map((time, index) => (
              <MenuItem key={index} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {tripType === "round_trip" && (
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} sm={6} md={5} mb={5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Return Date"
                value={returnDate}
                onChange={(newValue) =>
                  dispatch(setSearchTripDetails({ returnDate: newValue }))
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
                minDate={departureDate || new Date()}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={5} mb={5}>
            <TextField
              select
              label="Select Return Time"
              value={returnTime}
              onChange={(e) =>
                dispatch(setSearchTripDetails({ returnTime: e.target.value }))
              }
              fullWidth
            >
              <MenuItem value="">Select Return Time</MenuItem>
              {timeOptions.map((time, index) => (
                <MenuItem key={index} value={time}>
                  {time}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sm={6} md={5} mb={5}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={!isFormValid || loading}
            size="large"
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Continue"}
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={openAddDestination}
        onClose={() => handleCloseAddDestinationModal(null)} // Handle closing without adding
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add a Destination</DialogTitle>
        <DialogContent>
          <AddDestinationComponent
            onAddDestination={(newDestination) =>
              handleCloseAddDestinationModal(newDestination)
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchBookingComponent;
