import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import CustomInput from "../../forms/input/CustomInput";
import CustomIconComponent from "../../components/icon/CustomIconComponent";
import CustomButton from "../../forms/button/CustomButton";
import CustomSelect from "../../forms/select/CustomSelect";
import HelmetComponent from "../../components/helmet/HelmetComponent";
import { EDISPATCHED_HELMET, TIME_ZONE } from "../../utils/Constants";
import { fetchPOSTRequest } from "../../utils/Services";
import LOGO from "../../assets/images/logo-1024.png";
import { useLoader } from "../../context/LoaderContext";
import { useToast } from "../../context/ToastContext";
import { US_STATES } from "../../utils/StatesEnum";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { COUNTRIES } from "../../utils/CountriesEnum";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledContent = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  maxWidth: 480,
  padding: theme.spacing(8, 0),
}));

const SignupPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { isLoading, startLoading, stopLoading } = useLoader();

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required."),
    last_name: Yup.string().required("Last name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Enter a valid email."),
    password: Yup.string().required("Password is required."),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required."),
    business_name: Yup.string().required("Business name is required."),
    phone_number: Yup.string().required("Phone number is required."),
    phone_country_code: Yup.string().required("Country code is required."),
    country: Yup.string().required("Country is required."),
    street: Yup.string().required("Business Street is required."),
    city: Yup.string().required("Business City is required."),
    state: Yup.string().required("Business State is required."),
    zip: Yup.string().required("Business zip code is required."),
    primary_airport_code: Yup.string().required("Airport code is required."),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      business_name: "",
      phone_country_code: "",
      phone_number: "",
      street: "",
      unit: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      primary_airport_code: "",
      timezone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      startLoading();
      const { confirm_password, ...payload } = values; // Exclude confirm_password from payload
      const response = await fetchPOSTRequest(`/auth/owner/sign-up`, payload);
      if (response?.statusCode === 200 && response) {
        setToast({
          open: true,
          message: response?.message,
          severity: "success",
        });
        navigate(`/login`);
        stopLoading();
        formik.resetForm();
      } else {
        setToast({
          open: true,
          message: response?.message,
          severity: "error",
        });
        stopLoading();
      }
    },
  });

  useEffect(() => {
    if (formik.values.password === formik.values.confirm_password) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [formik.values.password, formik.values.confirm_password]);

  return (
    <>
      <HelmetComponent title={`${EDISPATCHED_HELMET} Register`} />
      <Container maxWidth="sm">
        <StyledContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={LOGO}
              alt="Logo"
              style={{
                width: "180px",
                height: "180px",
                background: "transparent",
              }}
            />
          </Box>
          <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
            Create an account
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="h5">Customer Information</Typography>
            <CustomInput
              name="first_name"
              label="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <CustomInput
              name="last_name"
              label="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <CustomInput
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <TextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
            />

            <TextField
              name="confirm_password"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!passwordsMatch}
              helperText={!passwordsMatch ? "Passwords do not match" : ""}
              fullWidth
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={4} mt={5}>
            <Typography variant="h5">Business Information</Typography>
            <CustomInput
              name="business_name"
              label="Business Name"
              value={formik.values.business_name}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <CustomSelect
              name="timezone"
              label="Airport Timezone"
              onChange={(event, newValue) =>
                formik.setFieldValue("timezone", newValue.value)
              }
              options={TIME_ZONE}
              value={formik.values.timezone}
              required={true}
            />
            <CustomInput
              name="phone_country_code"
              label="Country Code"
              value={formik.values.phone_country_code}
              onChange={formik.handleChange}
              formik={formik}
              helperText="+1"
              required={true}
            />
            <CustomInput
              name="phone_number"
              label="Phone Number"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              formik={formik}
              helperText="123-456-7890"
              required={true}
            />
            <CustomInput
              name="street"
              label="Business Street"
              value={formik.values.street}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <CustomInput
              name="unit"
              label="Unit"
              value={formik.values.unit}
              onChange={formik.handleChange}
              formik={formik}
            />
            <CustomInput
              name="city"
              label="Business City"
              value={formik.values.city}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <CustomSelect
              name="state"
              label="Business State"
              onChange={(event, newValue) =>
                formik.setFieldValue("state", newValue)
              }
              options={US_STATES}
              value={formik.values.state}
              required={true}
            />
            <CustomInput
              name="zip"
              label="Business Zip Code"
              value={formik.values.zip}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
            <CustomSelect
              name="country"
              label="Country"
              onChange={(event, newValue) =>
                formik.setFieldValue("country", newValue)
              }
              options={COUNTRIES}
              value={formik.values.country}
              required={true}
            />
            <CustomInput
              name="primary_airport_code"
              label="Airport Code"
              value={formik.values.primary_airport_code}
              onChange={formik.handleChange}
              formik={formik}
              required={true}
            />
          </Box>
          <Box mt={5}>
            <CustomButton
              label="Sign Up"
              size="large"
              onClick={formik.handleSubmit}
              disabled={isLoading}
              bgColor="#479DE1"
              isLoading={isLoading}
            />
          </Box>
          <Typography variant="body2" mt={2}>
            Already have an account?{" "}
            <Link href="login" variant="subtitle2">
              Login
            </Link>
          </Typography>
        </StyledContent>
      </Container>
    </>
  );
};

export default SignupPage;
