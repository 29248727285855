import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Stack,
  Box,
  FormLabel,
  FormControl,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import { useLoader } from "../../context/LoaderContext";
import { fetchPOSTRequest, fetchGETRequest } from "../../utils/Services";
import BackButton from "../backButton/BackButton";

const AddMaintenanceLogsComponent = () => {
  const navigate = useNavigate();
  const { setToast } = useToast();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const [allTailNumbers, setAllTailNumbers] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [signature, setSignature] = useState(null);
  const [isCanvasDirty, setIsCanvasDirty] = useState(false);

  const [formValues, setFormValues] = useState({
    tail_number: "",
    date: null,
    engine_one_tach: "",
    hobbs: "",
    comments: "",
    cost: "",
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const getFleetData = async () => {
      let apiUrl = `/fleet/member/get-fleet`;

      if (auth?.role === "owner") {
        apiUrl = `/fleet/owner/get-fleet`;
      } else if (auth?.role === "admin") {
        apiUrl = `/fleet/admin/get-fleet`;
      }

      const response = await fetchGETRequest(apiUrl, {});
      if (response?.statusCode === 200 && response?.data) {
        const mainData = response?.data?.map((item) => item?.tail_number);
        setAllTailNumbers(mainData);
      }
    };
    getFleetData();
  }, []);

  const sigCanvas = useRef(null);

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
    setIsCanvasDirty(false);
  };

  const saveSignature = () => {
    const signatureURL = sigCanvas.current.toDataURL();
    setSignature(signatureURL);
    setToast({
      open: true,
      message: "Signature has been added.",
      severity: "success",
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.date) errors.date = "Date is required.";
    if (!formValues.tail_number)
      errors.tail_number = "Tail Number is required.";
    if (!signature) errors.signature = "Signature is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isFormValid = () => {
    return formValues.date && formValues.tail_number && signature;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    startLoading();

    const modifiedValues = {
      ...formValues,
      amt_signature: signature,
      performed_by_id: auth?.user_id,
      date: formValues.date ? new Date(formValues.date).toISOString() : null,
      engine_one_tach: formValues.engine_one_tach || null, // Convert empty strings to null
      engine_two_tach: formValues.engine_two_tach || null, // Convert empty strings to null
      hobbs: formValues.hobbs || null, // Convert empty strings to null
      cost: formValues.cost ? Math.round(parseFloat(formValues.cost) * 100) : 0, // Convert empty cost to 0
    };

    try {
      let apiUrl = `/fleet/member/add-maintenance-log`;

      if (auth?.role === "owner") {
        apiUrl = `/fleet/owner/add-maintenance-log`;
      } else if (auth?.role === "admin") {
        apiUrl = `/fleet/admin/add-maintenance-log`;
      }

      const response = await fetchPOSTRequest(apiUrl, modifiedValues);

      if (response && response.statusCode === 201) {
        setToast({
          open: true,
          message: response.message,
          severity: "success",
        });
        stopLoading();
        navigate("/maintenance-logs");
        setFormValues({
          tail_number: "",
          date: null,
          engine_one_tach: "",
          hobbs: "",
          comments: "",
          cost: "",
        });
        clearSignature();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setToast({
        open: true,
        message: error.message || "Failed to add maintenance log",
        severity: "error",
      });
      stopLoading();
    }
  };

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Box mb={3}>
        <BackButton title={"Add Maintenance Log"} />
      </Box>
      <Container maxWidth="sm">
        <Stack spacing={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              value={formValues.date}
              onChange={(newValue) =>
                setFormValues({ ...formValues, date: newValue })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!formErrors.date}
                  helperText={formErrors.date}
                  required
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            select
            label="Tail Number"
            name="tail_number"
            value={formValues.tail_number}
            onChange={handleInputChange}
            error={!!formErrors.tail_number}
            helperText={formErrors.tail_number}
            required
          >
            {allTailNumbers.map((tailNumber) => (
              <MenuItem key={tailNumber} value={tailNumber}>
                {tailNumber}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Engine One Tach"
            name="engine_one_tach"
            value={formValues.engine_one_tach}
            onChange={handleInputChange}
            type="number"
          />
          <TextField
            label="Hobbs"
            name="hobbs"
            value={formValues.hobbs}
            onChange={handleInputChange}
            type="number"
          />
          <TextField
            label="Comments"
            name="comments"
            value={formValues.comments}
            onChange={handleInputChange}
            multiline
            rows={4}
          />
          <TextField
            label="Cost (USD)"
            name="cost"
            value={formValues.cost}
            onChange={handleInputChange}
            type="number"
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Signature</FormLabel>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                width: 500,
                height: 200,
                className: "sigCanvas",
                style: {
                  backgroundColor: "white",
                  border: "2px solid #637381",
                },
              }}
              onBegin={() => setIsCanvasDirty(true)}
            />
            <Button
              onClick={clearSignature}
              disabled={!isCanvasDirty}
              style={{ color: isCanvasDirty ? "#FF4842" : null }}
            >
              Clear Signature
            </Button>
            <Button
              onClick={saveSignature}
              disabled={!isCanvasDirty || signature}
            >
              {signature ? "Signature Added" : "Add Signature"}
            </Button>
            {formErrors.signature && (
              <div style={{ color: "#d32f2f", fontSize: "0.75rem" }}>
                {formErrors.signature}
              </div>
            )}
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            size="large"
            disabled={!isFormValid() || isLoading}
          >
            Add Maintenance Log
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default AddMaintenanceLogsComponent;
