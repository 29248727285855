import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { setBookings } from "../../store/features/BookingsSlice";
import CustomButton from "../../forms/button/CustomButton";
import { fetchGETRequest } from "../../utils/Services";
import { useLoader } from "../../context/LoaderContext";
import {
  formatCurrency,
  formatDateLong,
  formatPhoneNumber,
} from "../../utils/Helper";
import { readableStatus } from "../../utils/Constants";
import { useToast } from "../../context/ToastContext";

import ScheduledPaymentCard from "./ScheduledPaymentCard";
import ScheduledPaymentDetailModal from "./scheduled-payments-modal/ScheduledPaymentDetailModal";

import { useModal } from "../../context/ModalContext";

const ScheduledPaymentDetailComponent = () => {
  const params = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const { toast, setToast } = useToast();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [bookingFound, setBookingFound] = useState(false); // Track if booking is found
  const [triggerCheck, setTriggerCheck] = useState(false); // Trigger to re-check after state update

  const { openModal } = useModal();

  const user = useSelector((state) => state.auth);

  const bookings = useSelector((state) => state.bookings?.bookings || {});
  const bookingId = params?.state?.booking_id;

  const findBookingInRedux = () => {
    const { upcomingBookings, pastBookings } = bookings;
    const allBookings = [...(upcomingBookings || []), ...(pastBookings || [])];
    return allBookings.find((item) => item?.booking_id === bookingId);
  };

  const getBookingsData = async () => {
    startLoading();

    try {
      let foundBooking = findBookingInRedux();

      if (foundBooking) {
        setBookingFound(true);
        stopLoading();
        return;
      }

      let apiUrl = `/booking/member/get-bookings`;
      if (user?.role === "owner") {
        apiUrl = `/booking/owner/get-bookings`;
      } else if (user?.role === "admin") {
        apiUrl = `/booking/admin/get-bookings`;
      }
      const response = await fetchGETRequest(apiUrl, {});

      if (response?.statusCode === 200 && response) {
        dispatch(
          setBookings({
            upcomingBookings: response?.upcomingBookings,
            pastBookings: response?.pastBookings,
          })
        );

        setTriggerCheck(true); // Trigger the re-check in a new render cycle
      } else {
        console.error("Error fetching bookings:", response);
      }
    } catch (error) {
      console.error("Error getting booking data:", error);
    } finally {
      stopLoading();
    }
  };

  const handleBookingDetails = () => {
    const foundBooking = findBookingInRedux();

    if (foundBooking) {
      setBookingFound(true);
    } else {
      getBookingsData();
    }
  };

  // Re-check Redux state after triggering a re-render
  useEffect(() => {
    if (triggerCheck) {
      const foundBooking = findBookingInRedux();

      if (foundBooking) {
        setBookingFound(true);
      } else {
        setToast({
          open: true,
          message: "Booking not found",
          severity: "error",
        });
      }
      setTriggerCheck(false); // Reset the trigger
    }
  }, [triggerCheck, bookings]); // Ensure this effect runs after the state has been updated

  // Navigate when booking is found
  useEffect(() => {
    if (bookingFound && bookingId) {
      navigate(`/bookings/${bookingId}`, {
        state: { booking_id: bookingId },
      });
    }
  }, [bookingFound, bookingId, navigate]);

  // Set initial payment details
  useEffect(() => {
    if (params?.state?.booking_id) {
      setPaymentDetails(params?.state);
    } else {
      setToast({
        open: true,
        message: "Booking ID not found",
        severity: "error",
      });
    }
  }, [params, setToast]);

  const getReminderDate = (dueDate) => {
    const date = new Date(dueDate);
    date.setDate(date.getDate() - 3);
    return formatDateLong(date);
  };

  return (
    <>
      <ScheduledPaymentCard
        title={"Detail"}
        action={
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <CustomButton
              label={"Delete"}
              size={"small"}
              disabled={false}
              bgColor={"#d32f2f"}
              hoverBgColor={"#ad1515"}
            />
            <CustomButton
              label={"Edit"}
              size={"small"}
              onClick={() => openModal("schedulePaymentDetail")}
              disabled={false}
              bgColor={"#479DE1"}
            />
          </Box>
        }
        component={
          <Grid container spacing={{ xs: 5, md: 3 }} columns={{ md: 12 }}>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Customer Name
              </Typography>
              <Typography paragraph align="center">
                {`${paymentDetails?.customer?.first_name} ${paymentDetails?.customer?.last_name}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Email
              </Typography>
              <Typography paragraph align="center">
                {paymentDetails?.customer?.email || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Phone Number
              </Typography>
              <Typography paragraph align="center">
                {formatPhoneNumber(paymentDetails?.customer?.phone_number) ||
                  "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Status
              </Typography>
              <Typography paragraph align="center">
                {readableStatus(paymentDetails?.status) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Amount
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(paymentDetails?.amount) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Schedule For
              </Typography>
              <Typography paragraph align="center">
                {formatDateLong(paymentDetails?.due_date) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Email Reminder Scheduled for
              </Typography>
              <Typography paragraph align="center">
                {getReminderDate(paymentDetails?.due_date) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Booking Details
              </Typography>
              <CustomButton
                width={"fit-content"}
                onClick={handleBookingDetails}
                label={"Go"}
                isLoading={isLoading}
                size={"small"}
              />
            </Grid>
          </Grid>
        }
      />
      <ScheduledPaymentDetailModal
        data={paymentDetails}
        paymentData={paymentDetails}
      />
    </>
  );
};

export default ScheduledPaymentDetailComponent;
