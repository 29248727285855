// Constants

// API url
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const WEB_PORTAL_URL = process.env.REACT_APP_WEB_PORTAL_URL;
export const CHECKWX_API_KEY = process.env.REACT_APP_CHECKWX_API_KEY;

// MX Merchant
export const MX_PAYMENT_FORM_URL = process.env.REACT_APP_MX_PAYMENT_FORM_URL;
export const MX_MERCHANT_CONNECTION_AIR_TPN_NUMBER =
  process.env.REACT_APP_MX_MERCHANT_CONNECTION_AIR_TPN_NUMBER;
export const MX_MERCHANT_CONNECTION_AIR_TOKEN =
  process.env.REACT_APP_MX_MERCHANT_CONNECTION_AIR_TOKEN;

// Helmet title
export const EDISPATCHED = "eDispatched-135";
export const EDISPATCHED_HELMET = "eDispatched-135 |";

// Documents Types
export const DOCUMENTS_TYPES = [
  "pilot_operating_handbook",
  "checklist",
  "weight_and_balance",
  "airworthiness_certificate",
  "registration",
  "insurance",
];

// Time Zone
export const TIME_ZONE = [
  { label: "America/New_York - Eastern Time (ET)", value: "America/New_York" },
  { label: "America/Chicago - Central Time (CT)", value: "America/Chicago" },
  { label: "America/Denver - Mountain Time (MT)", value: "America/Denver" },
  {
    label: "America/Los_Angeles - Pacific Time (PT)",
    value: "America/Los_Angeles",
  },
  {
    label: "America/Puerto_Rico - Atlantic Standard Time (AST)",
    value: "America/Puerto_Rico",
  },
];

export const SYNC_FUSION_SCHEDULER_KEY =
  "Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXtdcHRURGhZVUZ1WUQ=";

export const readableStatus = (status) => {
  if (status === "mx_merchant") {
    return "MX Merchant";
  } else if (status === "active") {
    return "Active";
  } else if (status === "invited") {
    return "Invited";
  } else if (status === "banned") {
    return "Banned";
  } else if (status === "inactive") {
    return "Inactive";
  } else if (status === "confirmed_with_balance_due") {
    return "Confirmed - Balance Due";
  } else if (status === "confirmed") {
    return "Confirmed";
  } else if (status === "completed") {
    return "Completed";
  } else if (status === "one_way") {
    return "One Way";
  } else if (status === "round_trip") {
    return "Round Trip";
  } else if (status === "cancelled" || status === "canceled") {
    return "Canceled";
  } else if (status === "refunded") {
    return "Refunded";
  }
};

export const readableUserRole = (role) => {
  if (role === "admin") {
    return "Admin";
  } else if (role === "owner") {
    return "Owner";
  } else if (role === "pilot") {
    return "Pilot";
  } else if (role === "customer") {
    return "Customer";
  } else if (role === "member") {
    return "Member";
  }
};
