import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { fetchPUTRequest } from "../../utils/Services";
import { useToast } from "../../context/ToastContext";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateBookingCustomer } from "../../store/features/BookingsSlice";

const EditCustomerModal = ({
  open,
  handleClose,
  customerData,
  onUpdateSuccess,
}) => {
  const { toast, setToast } = useToast();

  const [firstName, setFirstName] = useState(customerData.first_name);
  const [lastName, setLastName] = useState(customerData.last_name);
  const [email, setEmail] = useState(customerData.email);
  const [phoneNumber, setPhoneNumber] = useState(customerData.phone_number);
  const [nationality, setNationality] = useState(customerData.nationality);
  const [dateOfBirth, setDateOfBirth] = useState(customerData.date_of_birth);
  const [weight, setWeight] = useState(customerData.weight);
  const [street, setStreet] = useState(
    customerData.residential_address?.street
  );
  const [unit, setUnit] = useState(customerData.residential_address?.unit);
  const [city, setCity] = useState(customerData.residential_address?.city);
  const [state, setState] = useState(customerData.residential_address?.state);
  const [zipCode, setZipCode] = useState(
    customerData.residential_address?.zip_code
  );
  const [country, setCountry] = useState(
    customerData.residential_address?.country
  );
  const [passportNumber, setPassportNumber] = useState(
    customerData.documents?.passport?.passport_number || ""
  );
  const [passportExpiration, setPassportExpiration] = useState(
    customerData.documents?.passport?.passport_expiration || ""
  );
  const [passportDateOfIssue, setPassportDateOfIssue] = useState(
    customerData.documents?.passport?.date_of_issue || ""
  );
  const [driversLicenseNumber, setDriversLicenseNumber] = useState(
    customerData.documents?.drivers_license?.drivers_license_number || ""
  );
  const [driversLicenseExpiration, setDriversLicenseExpiration] = useState(
    customerData.documents?.drivers_license?.drivers_license_expiration || ""
  );

  const params = useLocation();
  const dispatch = useDispatch();
  const bookings = useSelector((state) => state.bookings);

  const user = useSelector((state) => state.auth);

  useEffect(() => {
    setFirstName(customerData.first_name);
    setLastName(customerData.last_name);
    setEmail(customerData.email);
    setPhoneNumber(customerData.phone_number);
    setNationality(customerData.nationality);
    setDateOfBirth(customerData.date_of_birth);
    setWeight(customerData.weight);
    setStreet(customerData.residential_address?.street);
    setUnit(customerData.residential_address?.unit);
    setCity(customerData.residential_address?.city);
    setState(customerData.residential_address?.state);
    setZipCode(customerData.residential_address?.zip_code);
    setCountry(customerData.residential_address?.country);
    setPassportNumber(customerData.documents?.passport?.passport_number || "");
    setPassportExpiration(
      customerData.documents?.passport?.passport_expiration || ""
    );
    setPassportDateOfIssue(
      customerData.documents?.passport?.date_of_issue || ""
    );
    setDriversLicenseNumber(
      customerData.documents?.drivers_license?.drivers_license_number || ""
    );
    setDriversLicenseExpiration(
      customerData.documents?.drivers_license?.drivers_license_expiration || ""
    );
  }, [customerData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "first_name":
        setFirstName(value);
        break;
      case "last_name":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone_number":
        setPhoneNumber(value);
        break;
      case "nationality":
        setNationality(value);
        break;
      case "date_of_birth":
        setDateOfBirth(value);
        break;
      case "weight":
        setWeight(value);
        break;
      case "residential_address.street":
        setStreet(value);
        break;
      case "residential_address.unit":
        setUnit(value);
        break;
      case "residential_address.city":
        setCity(value);
        break;
      case "residential_address.state":
        setState(value);
        break;
      case "residential_address.zip_code":
        setZipCode(value);
        break;
      case "residential_address.country":
        setCountry(value);
        break;
      case "documents.passport.passport_number":
        setPassportNumber(value);
        break;
      case "documents.passport.passport_expiration":
        setPassportExpiration(value);
        break;
      case "documents.passport.date_of_issue":
        setPassportDateOfIssue(value);
        break;
      case "documents.drivers_license.drivers_license_number":
        setDriversLicenseNumber(value);
        break;
      case "documents.drivers_license.drivers_license_expiration":
        setDriversLicenseExpiration(value);
        break;
      default:
        break;
    }
  };

  const getModifiedValues = () => {
    const modifiedValues = { customer_id: customerData.customer_id };

    if (customerData.first_name !== firstName) {
      modifiedValues.first_name = firstName;
    }
    if (customerData.last_name !== lastName) {
      modifiedValues.last_name = lastName;
    }
    if (customerData.email !== email) {
      modifiedValues.email = email;
    }
    if (customerData.phone_number !== phoneNumber) {
      modifiedValues.phone_number = phoneNumber;
    }
    if (customerData.nationality !== nationality) {
      modifiedValues.nationality = nationality;
    }
    if (customerData.date_of_birth !== dateOfBirth) {
      modifiedValues.date_of_birth = dateOfBirth;
    }
    if (customerData.weight !== weight) {
      modifiedValues.weight = weight;
    }

    const residentialAddress = {};
    if (customerData.residential_address?.street !== street) {
      residentialAddress.street = street;
    }
    if (customerData.residential_address?.unit !== unit) {
      residentialAddress.unit = unit;
    }
    if (customerData.residential_address?.city !== city) {
      residentialAddress.city = city;
    }
    if (customerData.residential_address?.state !== state) {
      residentialAddress.state = state;
    }
    if (customerData.residential_address?.zip_code !== zipCode) {
      residentialAddress.zip_code = zipCode;
    }
    if (customerData.residential_address?.country !== country) {
      residentialAddress.country = country;
    }

    if (Object.keys(residentialAddress).length > 0) {
      modifiedValues.residential_address = residentialAddress;
    }

    const documents = {};
    const passport = {};
    if (customerData.documents?.passport?.passport_number !== passportNumber) {
      passport.passport_number = passportNumber;
    }
    if (
      customerData.documents?.passport?.passport_expiration !==
      passportExpiration
    ) {
      passport.passport_expiration = passportExpiration;
    }
    if (
      customerData.documents?.passport?.date_of_issue !== passportDateOfIssue
    ) {
      passport.date_of_issue = passportDateOfIssue;
    }

    if (Object.keys(passport).length > 0) {
      documents.passport = passport;
    }

    const driversLicense = {};
    if (
      customerData.documents?.drivers_license?.drivers_license_number !==
      driversLicenseNumber
    ) {
      driversLicense.drivers_license_number = driversLicenseNumber;
    }
    if (
      customerData.documents?.drivers_license?.drivers_license_expiration !==
      driversLicenseExpiration
    ) {
      driversLicense.drivers_license_expiration = driversLicenseExpiration;
    }

    if (Object.keys(driversLicense).length > 0) {
      documents.drivers_license = driversLicense;
    }

    if (Object.keys(documents).length > 0) {
      modifiedValues.documents = documents;
    }

    return modifiedValues;
  };

  const handleUpdate = async () => {
    const modifiedValues = getModifiedValues();

    if (Object.keys(modifiedValues).length === 1) {
      setToast({
        open: true,
        message: "No changes detected.",
        severity: "info",
      });
      return;
    }

    try {
      let apiUrl = `/internal-customer/member/edit-customer-details`;
      if (user?.role === "owner") {
        apiUrl = `/internal-customer/owner/edit-customer-details`;
      } else if (user?.role === "admin") {
        apiUrl = `/internal-customer/admin/edit-customer-details`;
      }

      const response = await fetchPUTRequest(apiUrl, modifiedValues);

      if (response && response.statusCode === 200) {
        const bookingId = params.state.booking_id;
        const updatedCustomer = response.customer;

        dispatch(updateBookingCustomer({ updatedCustomer, bookingId }));

        setToast({
          open: true,
          message: "Customer details updated successfully.",
          severity: "success",
        });

        if (typeof onUpdateSuccess === "function") {
          onUpdateSuccess(modifiedValues);
        }

        handleClose();
      } else {
        setToast({
          open: true,
          message: response.Message,
          severity: "error",
        });
      }
    } catch (error) {
      setToast({
        open: true,
        message: error.Message || "An error occurred. Please try again.",
        severity: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Customer Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please edit the necessary fields and click "Update" to save changes.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="First Name"
              type="text"
              fullWidth
              name="first_name"
              value={firstName || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Last Name"
              type="text"
              fullWidth
              name="last_name"
              value={lastName || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              name="email"
              value={email || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Phone Number"
              type="text"
              fullWidth
              name="phone_number"
              value={phoneNumber || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Nationality"
              type="text"
              fullWidth
              name="nationality"
              value={nationality || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Date Of Birth"
              type="date"
              fullWidth
              name="date_of_birth"
              value={dateOfBirth || ""}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              label="Weight"
              type="number"
              fullWidth
              name="weight"
              value={weight || ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Residential Address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Street"
              type="text"
              fullWidth
              name="residential_address.street"
              value={street || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Unit"
              type="text"
              fullWidth
              name="residential_address.unit"
              value={unit || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin="dense"
              label="City"
              type="text"
              fullWidth
              name="residential_address.city"
              value={city || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin="dense"
              label="State"
              type="text"
              fullWidth
              name="residential_address.state"
              value={state || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin="dense"
              label="Zip Code"
              type="text"
              fullWidth
              name="residential_address.zip_code"
              value={zipCode || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin="dense"
              label="Country"
              type="text"
              fullWidth
              name="residential_address.country"
              value={country || ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Documents
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Passport
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Passport Number"
              type="text"
              fullWidth
              name="documents.passport.passport_number"
              value={passportNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Passport Expiration"
              type="date"
              fullWidth
              name="documents.passport.passport_expiration"
              value={passportExpiration}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Date Of Issue"
              type="date"
              fullWidth
              name="documents.passport.date_of_issue"
              value={passportDateOfIssue}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Driver's License
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Driver's License Number"
              type="text"
              fullWidth
              name="documents.drivers_license.drivers_license_number"
              value={driversLicenseNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Driver's License Expiration"
              type="date"
              fullWidth
              name="documents.drivers_license.drivers_license_expiration"
              value={driversLicenseExpiration}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomerModal;
