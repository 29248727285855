import React, { useState } from "react";
import Container from "@mui/material/Container";
import BookingDetailComponent from "./BookingDetailComponent";
import BookingCustomerComponent from "./BookingCustomerComponent";
import BookingFlightSegmentsComponent from "./BookingFlightSegmentsComponent";
import BookingPassengersComponent from "./BookingPassengersComponent";

import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { fetchPOSTRequest } from "../../utils/Services";
import { useToast } from "../../context/ToastContext";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../utils/Helper";

const Header = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
}));

const BookingViewComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast, setToast } = useToast();
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [refundType, setRefundType] = useState("");
  const [partialRefundAmount, setPartialRefundAmount] = useState("");

  const user = useSelector((state) => state.auth);

  // Get the booking ID from the navigation state
  const bookingId = location?.state?.booking_id;

  // Access the Redux state
  const bookings = useSelector((state) => state.bookings?.bookings);

  // Find the booking in the Redux store using the booking ID
  const booking = [
    ...(bookings?.upcomingBookings || []),
    ...(bookings?.pastBookings || []),
  ].find((b) => b.booking_id === bookingId);

  const bookingStatus = booking?.status;
  const amountPaid = booking?.amount_paid;
  const customer = booking?.customer;

  const handleDeleteClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmationOpen(false);
  };

  const handleFinalConfirm = async () => {
    if (refundType === "partial" && partialRefundAmount > amountPaid) {
      setToast({
        open: true,
        message: "Partial refund amount cannot exceed the amount paid.",
        severity: "error",
      });
      setConfirmationOpen(false);
      return;
    }

    const bookingCancellationData = {
      booking_id: bookingId,
      is_issue_full_refund: refundType === "full",
      is_issue_partial_refund: refundType === "partial",
      partial_refund_amount:
        refundType === "partial" ? partialRefundAmount : null,
    };

    try {
      let apiUrl = `/booking/member/cancel-booking`;
      if (user?.role === "owner") {
        apiUrl = `/booking/owner/cancel-booking`;
      } else if (user?.role === "admin") {
        apiUrl = `/booking/admin/cancel-booking`;
      }

      const response = await fetchPOSTRequest(apiUrl, bookingCancellationData);

      if (response.statusCode === 200) {
        setToast({
          open: true,
          message: "Booking canceled successfully",
          severity: "success",
        });
        navigate("/bookings");
      } else {
        setToast({ open: true, message: response.message, severity: "error" });
      }
    } catch (error) {
      setToast({
        open: true,
        message: "An error occurred. Please try again.",
        severity: "error",
      });
    }

    handleConfirmClose();
  };

  const handleConfirm = () => {
    setOpen(false);
    setConfirmationOpen(true);
  };

  // Handle case when booking is not found
  if (!booking) {
    return (
      <Container maxWidth="xl">
        <Header>
          <IconButton onClick={() => navigate(-1)} aria-label="back">
            <ArrowBackIosNewIcon color="action" />
          </IconButton>
          <Typography variant="h4">Booking not found</Typography>
        </Header>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Header>
        <IconButton onClick={() => navigate(-1)} aria-label="back">
          <ArrowBackIosNewIcon color="action" />
        </IconButton>
        <Typography variant="h4">
          Booking Reference - {booking?.booking_reference}
        </Typography>
      </Header>
      <BookingDetailComponent bookingId={bookingId} />
      <BookingCustomerComponent bookingId={bookingId} />
      <BookingPassengersComponent bookingId={bookingId} />
      <BookingFlightSegmentsComponent bookingId={bookingId} />

      {(bookingStatus === "confirmed_with_balance_due" ||
        bookingStatus === "confirmed") && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <LoadingButton
            loading={false}
            variant="contained"
            size="large"
            sx={{ width: "40%" }}
            color="error"
            onClick={handleDeleteClick}
          >
            Cancel Booking
          </LoadingButton>
        </Box>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cancel Booking</DialogTitle>
        <DialogContent>
          <DialogContentText mb={5}>
            Please select the type of refund you want to issue.{" "}
            {customer?.first_name} {customer?.last_name} has paid{" "}
            {formatCurrency(amountPaid)}. We will issue a full refund or a
            partial refund based on your selection.
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel>Refund Type</InputLabel>
            <Select
              value={refundType}
              onChange={(e) => setRefundType(e.target.value)}
              label="Refund Type"
            >
              <MenuItem value="full">Issue Full Refund</MenuItem>
              <MenuItem value="partial">Issue Partial Refund</MenuItem>
            </Select>
          </FormControl>
          {refundType === "partial" && (
            <TextField
              margin="dense"
              label="Partial Refund Amount"
              type="number"
              fullWidth
              value={partialRefundAmount}
              onChange={(e) => setPartialRefundAmount(e.target.value)}
              inputProps={{ max: amountPaid }}
              helperText={`Maximum amount: ${formatCurrency(amountPaid)}`}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to confirm this action?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={handleConfirmClose}
          >
            Close
          </Button>
          <Button
            onClick={handleFinalConfirm}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BookingViewComponent;
