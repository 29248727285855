// Destination view component

// MUI components
import Container from "@mui/material/Container";

// Custom
import DestinationDetailComponent from "./DestinationDetailComponent";
import BackButton from "../backButton/BackButton";
import DestinationRulesCardComponent from "./DestinationRulesCardComponent";

const DestinationViewComponent = () => {
  return (
    <Container maxWidth="lg">
      <BackButton title={"Destinations"} />
      <DestinationDetailComponent />
      <DestinationRulesCardComponent />
    </Container>
  );
};

export default DestinationViewComponent;
