export const FONT_PRIMARY = "Public Sans, sans-serif";

export const FONT_COLOR = {
  PRIMARY: "#212b36",
  LINK: "#479DE1",
};

export const TEXT_FIELD = {
  borderColor: "#cccccc",
  borderFocusedColor: "#479DE1",
  labelColor: "#637381",
  labelFocusedColor: "#479DE1",
};

export const NAV_LIST = {
  navColor: "#637381",
  selectedNavColor: "#212b36",
  selectedNavBgColor: "rgba(145, 158, 171, 0.16)",
};

export const CHIP = {
  success: "#229a16",
  danger: "#ff4842",
  info: "#1890ff",
};

export const TABLE = {
  headBgColor: "#f4f6f8",
  headTextColor: "#637381",
};

export const COLOR_OBJECT = {
  primary: "#479DE1",
};
