import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Modal from "../../../modal/Modal";
import CustomInput from "../../../../forms/input/CustomInput";
import CustomButton from "../../../../forms/button/CustomButton";
import CustomSelect from "../../../../forms/select/CustomSelect";
import { useModal } from "../../../../context/ModalContext";
import { useToast } from "../../../../context/ToastContext";
import { useLoader } from "../../../../context/LoaderContext";
import { useDispatch, useSelector } from "react-redux";
import { setFleetDetails } from "../../../../store/features/FleetSlice";
import { fetchPUTRequest } from "../../../../utils/Services";

const DetailsSectionModal = ({ details, setDetails }) => {
  const { isModal, closeModal } = useModal();
  const { setToast } = useToast();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const dispatch = useDispatch();
  const fleet = useSelector((state) => state.fleet);
  const [initialValues, setInitialValues] = useState({});

  const user = useSelector((state) => state.auth);

  const CATEGORY_OPTIONS = [
    { label: "ASEL", value: "airplane_single_engine_land" },
    { label: "AMEL", value: "airplane_multi_engine_land" },
  ];

  const formik = useFormik({
    initialValues: {
      tail_number: details.tail_number || "",
      cruise_speed_kts: details.cruise_speed_kts || "",
      year: details.year || "",
      make: details.make || "",
      model: details.model || "",
      passenger_seats: details.passenger_seats || "",
      category:
        CATEGORY_OPTIONS.find((item) => item.value === details.category) || "",
      icao_equipment: details.icao_equipment || "",
      icao_surveillance_codes: details.icao_surveillance_codes || "",
      serial_number: details.serial_number || "",
      faa_designator: details.faa_designator || "",
      fuel_burn_rate: details.fuel_burn_rate || "",
      empty_weight: details.empty_weight || "",
      fuel_capacity: details.fuel_capacity || "",
      hourly_rate: details.hourly_rate || "",
    },
    onSubmit: async (values) => {
      const newPayload = { ...values, hourly_rate: values.hourly_rate * 100 };
      newPayload["category"] = newPayload["category"].value;
      if (fleet?.details?.tail_number !== formik.values.tail_number) {
        newPayload["updated_tail_number"] = formik.values.tail_number;
        newPayload["tail_number"] = fleet?.details?.tail_number;
      }
      startLoading();

      let apiUrl = `/fleet/member/edit-plane`;

      if (user?.role === "owner") {
        apiUrl = `/fleet/owner/edit-plane`;
      } else if (user?.role === "admin") {
        apiUrl = `/fleet/admin/edit-plane`;
      }

      const response = await fetchPUTRequest(apiUrl, newPayload);

      if (response?.statusCode === 200 && response) {
        setToast({
          open: true,
          message: response?.Message,
          severity: "success",
        });

        closeModal();
        stopLoading();

        const newObj = { ...fleet.details };

        Object.assign(newObj, response?.data);

        const updatedDetails = {
          ...details,
          ...response.data,
          hourly_rate: response.data.hourly_rate / 100,
        };

        dispatch(setFleetDetails(newObj));
        setDetails(updatedDetails);
        formik.resetForm();
      } else {
        setToast({
          open: true,
          message: response?.Message,
          severity: "error",
        });
        stopLoading();
        formik.resetForm();
        closeModal();
      }
    },
  });

  useEffect(() => {
    if (details) {
      const updatedInitialValues = {};
      Object.entries(details).forEach(([key, value]) => {
        if (key === "category") {
          const findValue = CATEGORY_OPTIONS.find((cat) => cat.value === value);
          formik.setFieldValue(key, findValue || "");
          updatedInitialValues[key] = findValue;
        } else {
          formik.setFieldValue(key, value || "");
          updatedInitialValues[key] = value;
        }
      });
      setInitialValues(updatedInitialValues);
    }
  }, [details]);

  // Check if required fields are filled
  const isUpdateDisabled =
    !formik.values.tail_number ||
    !formik.values.year ||
    !formik.values.make ||
    !formik.values.model ||
    !formik.values.cruise_speed_kts ||
    !formik.values.hourly_rate ||
    !formik.values.passenger_seats;

  return (
    <Modal
      title={"Update Details"}
      open={isModal.open && isModal.type === "Details"}
      content={
        <Stack display={"flex"} flexDirection={"column"} gap={5}>
          <CustomInput
            name="tail_number"
            value={formik.values.tail_number}
            label="Tail Number"
            onChange={formik.handleChange}
            formik={formik}
            required={true}
          />
          <CustomSelect
            name="category"
            label="Category"
            onChange={(_, newValue) => {
              formik.setFieldValue("category", newValue);
            }}
            options={CATEGORY_OPTIONS}
            formik={formik}
            value={formik.values.category}
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomInput
                name="year"
                label="Year"
                value={formik.values.year}
                onChange={formik.handleChange}
                formik={formik}
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="make"
                label="Make"
                value={formik.values.make}
                onChange={formik.handleChange}
                formik={formik}
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="model"
                label="Model"
                value={formik.values.model}
                onChange={formik.handleChange}
                formik={formik}
                required={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomInput
                name="cruise_speed_kts"
                label="Cruise Speed (kts)"
                value={formik.values.cruise_speed_kts}
                onChange={formik.handleChange}
                formik={formik}
                required={true}
                type="number"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="hourly_rate"
                label="Hourly Rate"
                type="number"
                value={formik.values.hourly_rate}
                onChange={formik.handleChange}
                formik={formik}
                required={true}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="passenger_seats"
                label="Passenger Seats"
                type="number"
                value={formik.values.passenger_seats}
                onChange={formik.handleChange}
                formik={formik}
                required={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomInput
                name="serial_number"
                value={formik.values.serial_number}
                label="Serial Number"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="fuel_capacity"
                value={formik.values.fuel_capacity}
                type="number"
                label="Fuel Capacity"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="total_seats"
                label="Total Seats"
                type="number"
                value={formik.values.total_seats}
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomInput
                name="faa_designator"
                value={formik.values.faa_designator}
                label="Faa Designator"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="fuel_burn_rate"
                value={formik.values.fuel_burn_rate}
                type="number"
                label="Fuel Burn Rate"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="empty_weight"
                value={formik.values.empty_weight}
                type="number"
                label="Empty Weight"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomInput
                name="icao_equipment"
                value={formik.values.icao_equipment}
                label="Icao Equipment"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                name="icao_surveillance_codes"
                value={formik.values.icao_surveillance_codes}
                label="Icao Surveillance Codes"
                onChange={formik.handleChange}
                formik={formik}
              />
            </Grid>
          </Grid>
        </Stack>
      }
      action={
        <CustomButton
          label={"Update"}
          size={"medium"}
          width={"fit-content"}
          onClick={formik.handleSubmit}
          disabled={isUpdateDisabled}
          isLoading={isLoading}
          bgColor={"#479DE1"}
        />
      }
    />
  );
};

export default DetailsSectionModal;
