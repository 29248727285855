import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI components
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setBookings } from "../../store/features/BookingsSlice";

// Context
import { useLoader } from "../../context/LoaderContext";

// Custom
import SpinnerComponent from "../../components/spinner/SpinnerComponent";
import CustomButton from "../../forms/button/CustomButton";
import Label from "../../components/label";
import HelmetComponent from "../../components/helmet/HelmetComponent";

// Utils
import { fetchGETRequest } from "../../utils/Services";
import { EDISPATCHED_HELMET, readableStatus } from "../../utils/Constants";
import {
  formatCurrency,
  formatDate,
  renderChipColorByStatus,
} from "../../utils/Helper";
import { Stack, Tab, Tabs } from "@mui/material";
import BookingsToolbar from "../../components/bookings/BookingsToolbar";

const BookingsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { isLoading, startLoading, stopLoading } = useLoader();
  const state = useSelector((state) => state.bookings);
  const [searchText, setSearchText] = useState("");
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [value, setValue] = useState("upcoming");

  const role = useSelector((state) => state.auth.role);

  const TABLE_HEAD = [
    { id: "index", label: "#" },
    { id: "tail_number", label: "Tail Number" },
    { id: "departure_date", label: "Departure Date" },
    { id: "trip_departure_airport_code", label: "Departure" },
    { id: "trip_arrival_airport_code", label: "Arrival" },
    { id: "customer_name", label: "Customer Name" },
    { id: "booking_reference", label: "Booking Reference" },
    { id: "total_price", label: "Total Price" },
    { id: "amount_paid", label: "Amount Paid" },
    { id: "number_of_passengers", label: "# of Passengers" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
    setSearchText(""); // Clear search text when switching tabs
  };

  useEffect(() => {
    const getBookingsData = async () => {
      startLoading();

      let endpoint = "/booking/member/get-bookings";
      if (role === "admin") {
        endpoint = "/booking/admin/get-bookings";
      } else if (role === "owner") {
        endpoint = "/booking/owner/get-bookings";
      }

      const response = await fetchGETRequest(endpoint, {});

      if (response?.statusCode === 200 && response) {
        dispatch(
          setBookings({
            upcomingBookings: response?.upcomingBookings,
            pastBookings: response?.pastBookings,
          })
        );
      }
      stopLoading();
    };
    getBookingsData();
  }, [dispatch]);

  const handleView = (bookingId) => {
    // Find the booking in upcoming or past bookings
    const bookingData = [
      ...state.bookings.upcomingBookings,
      ...state.bookings.pastBookings,
    ].find((booking) => booking.booking_id === bookingId);

    if (bookingData) {
      navigate(`/bookings/${bookingId}`, {
        state: { booking_id: bookingId },
      });
    } else {
      console.error("Booking not found in Redux state.");
      // Optionally, handle the case where the booking is not found in Redux state
    }
  };

  useEffect(() => {
    const filterBookingData = () => {
      const bookings =
        value === "upcoming"
          ? state?.bookings?.upcomingBookings || []
          : state?.bookings?.pastBookings || [];

      if (!searchText) {
        return bookings;
      }
      return bookings.filter((item) => {
        const booking = `${item.booking_reference}`.toLowerCase();
        return booking.includes(searchText.toLowerCase());
      });
    };

    const result = filterBookingData();
    setFilteredBookings(result);
  }, [searchText, state.bookings, value]);

  return (
    <>
      <HelmetComponent title={`${EDISPATCHED_HELMET} Bookings`} />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          mb={5}
        >
          <Typography variant="h4" gutterBottom mb={0}>
            Bookings
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs value={value} onChange={handleTabChange} centered>
              <Tab label="Upcoming Bookings" value="upcoming" />
              <Tab label="Past Bookings" value="past" />
            </Tabs>
          </Box>
        </Stack>

        <Card>
          <BookingsToolbar
            searchValue={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((header) => (
                    <TableCell key={header.id} align="center">
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBookings.length > 0 ? (
                  filteredBookings.map((booking, index) => (
                    <TableRow hover key={booking.booking_id || index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {booking.tail_number}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(booking.trip_departure_flight_time)}
                      </TableCell>
                      <TableCell align="center">
                        {booking.trip_departure_airport_code}
                      </TableCell>
                      <TableCell align="center">
                        {booking.trip_arrival_airport_code}
                      </TableCell>
                      <TableCell align="center">
                        {`${booking.customer.first_name} ${booking.customer.last_name}`}
                      </TableCell>
                      <TableCell align="center">
                        {booking.booking_reference}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(booking.total_price)}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(booking.amount_paid)}
                      </TableCell>
                      <TableCell align="center">
                        {booking.number_of_passengers}
                      </TableCell>
                      <TableCell align="center">
                        <Label color={renderChipColorByStatus(booking.status)}>
                          {readableStatus(booking.status)}
                        </Label>
                      </TableCell>
                      <TableCell align="center">
                        <CustomButton
                          size="small"
                          width="fit-content"
                          onClick={() => handleView(booking.booking_id)}
                          label="View"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={TABLE_HEAD.length} align="center">
                      No bookings available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {filteredBookings.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={filteredBookings.length}
              rowsPerPage={limit}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
            />
          )}
        </Card>
      </Container>
    </>
  );
};

export default BookingsPage;
