import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// MUI components
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Custom
import BookingCardComponent from "./BookingCardComponent";

const BookingFlightSegmentsComponent = ({ bookingId }) => {
  const [bookingFlightSegments, setBookingFlightSegments] = useState(null);

  // Find the booking in both upcoming and past bookings using Redux state
  const booking = useSelector((state) => {
    const { upcomingBookings, pastBookings } = state.bookings?.bookings || {};
    const allBookings = [...(upcomingBookings || []), ...(pastBookings || [])];
    return allBookings.find((b) => b.booking_id === bookingId);
  });

  const TABLE_HEAD = [
    { id: "trip_leg_number", label: "Trip Leg Number" },
    { id: "departure_airport_code", label: "Departure Airport Code " },
    { id: "arrival_airport_code", label: "Arrival Airport Code" },
    { id: "departing_flight_time", label: "Departing Flight Time" },
    { id: "arrival_time", label: "Estimated Arrival" },
    { id: "empty_leg", label: "Empty Leg" },
  ];

  useEffect(() => {
    if (booking && booking.flight_segments) {
      // Sort the segments by trip_leg_number
      const sortedSegments = [...booking.flight_segments].sort(
        (a, b) => a.trip_leg_number - b.trip_leg_number
      );
      setBookingFlightSegments(sortedSegments);
    }
  }, [booking]);

  return (
    <>
      <BookingCardComponent
        title={"Booking Flight Segments"}
        component={
          bookingFlightSegments?.length > 0 ? (
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((header) => (
                        <TableCell key={header.id} align="center">
                          {header.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(bookingFlightSegments) &&
                      bookingFlightSegments.map((segment, index) => (
                        <TableRow hover key={index}>
                          <TableCell align="center">
                            {segment?.trip_leg_number}
                          </TableCell>
                          <TableCell align="center">
                            {segment?.departure_airport_code}
                          </TableCell>

                          <TableCell align="center">
                            {segment?.arrival_airport_code}
                          </TableCell>
                          <TableCell align="center">
                            {moment(segment?.departing_flight_time).format(
                              "h:mm a"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(segment?.arrival_time).format("h:mm a")}
                          </TableCell>
                          <TableCell align="center">
                            {segment?.is_segment_with_passenger ? "No" : "Yes"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={500}
                textAlign={"center"}
                color={"Gray"}
              >
                No data available
              </Typography>
            </Box>
          )
        }
      />
    </>
  );
};

export default BookingFlightSegmentsComponent;
