import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  MenuItem,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/system";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Modal from "../modal/Modal";
import { fetchPUTRequest, fetchGETRequest } from "../../utils/Services";
import { useToast } from "../../context/ToastContext";
import { useModal } from "../../context/ModalContext";
import { useLoader } from "../../context/LoaderContext";
import { setDestinations } from "../../store/features/DestinationsSlice";
import { useParams } from "react-router-dom";
import AddDestinationComponent from "../destinations/AddDestinationComponent";
import CustomButton from "../../forms/button/CustomButton";

const StyledCard = styled(Card)(({ theme }) => ({
  border: "1px solid #ddd",
  margin: "35px 0",
  boxShadow: "none",
  borderRadius: "10px",
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  background: "#f2f5f7",
  borderBottom: "1px solid #ddd",
  padding: "15px 25px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const headerTitleStyle = {
  alignItems: "center",
  display: "flex",
};

const DestinationRulesCardComponent = () => {
  const { destination_id } = useParams(); // Extract destination_id from the URL
  const destinations = useSelector((state) => state.destinations.destinations);
  const dispatch = useDispatch();
  const { setToast } = useToast();
  const { isModal, closeModal, openModal } = useModal();
  const { isLoading, startLoading, stopLoading } = useLoader();

  const user = useSelector((state) => state.auth);

  // Fetch the destination from Redux using the destination_id
  const destination = destinations.find(
    (item) => item.destination_id === destination_id
  );

  const [formValues, setFormValues] = useState({});

  const [openAddDestination, setOpenAddDestination] = useState(false);
  const [destinationType, setDestinationType] = useState("");

  useEffect(() => {
    if (destination) {
      setFormValues({
        add_segment_on_domestic_departure:
          destination.destination_specific_rules
            .add_segment_on_domestic_departure || false,
        add_segment_on_domestic_arrival:
          destination.destination_specific_rules
            .add_segment_on_domestic_arrival || false,
        add_segment_on_international_departure:
          destination.destination_specific_rules
            .add_segment_on_international_departure || false,
        add_segment_on_international_arrival:
          destination.destination_specific_rules
            .add_segment_on_international_arrival || false,
        airport_code_for_domestic_departure_segment:
          destination.destination_specific_rules
            .airport_code_for_domestic_departure_segment || "",
        airport_code_for_domestic_arrival_segment:
          destination.destination_specific_rules
            .airport_code_for_domestic_arrival_segment || "",
        airport_code_for_international_departure_segment:
          destination.destination_specific_rules
            .airport_code_for_international_departure_segment || "",
        airport_code_for_international_arrival_segment:
          destination.destination_specific_rules
            .airport_code_for_international_arrival_segment || "",
        add_wait_time_on_arrival:
          destination.destination_specific_rules.add_wait_time_on_arrival ||
          false,
        minutes_to_add_on_arrival:
          destination.destination_specific_rules.minutes_to_add_on_arrival || 0,
        add_wait_time_on_departure:
          destination.destination_specific_rules.add_wait_time_on_departure ||
          false,
        minutes_to_add_on_departure:
          destination.destination_specific_rules.minutes_to_add_on_departure ||
          0,
      });
    }
  }, [destination]);

  const handleOpen = () => openModal("editDestinationRules");
  const handleClose = () => closeModal();

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Convert "true"/"false" strings to boolean values
    const parsedValue =
      value === "true" ? true : value === "false" ? false : value;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: parsedValue,
    }));
  };

  const handleOpenAddDestinationModal = (type) => {
    setDestinationType(type);
    setOpenAddDestination(true);
  };

  const handleCloseAddDestinationModal = async (newDestination) => {
    setOpenAddDestination(false);

    let apiUrl = `/destination/member/get-destinations`;

    if (user?.role === "admin") {
      apiUrl = `/destination/admin/get-destinations`;
    } else if (user?.role === "owner") {
      apiUrl = `/destination/owner/get-destinations`;
    }

    // Refetch destinations after adding a new one

    const response = await fetchGETRequest(apiUrl, {});

    if (response?.statusCode === 200 && response.destinations) {
      dispatch(setDestinations(response.destinations));
    }

    if (newDestination) {
      if (destinationType === "domestic_departure") {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          airport_code_for_domestic_departure_segment:
            newDestination.airport_code,
        }));
      } else if (destinationType === "domestic_arrival") {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          airport_code_for_domestic_arrival_segment:
            newDestination.airport_code,
        }));
      } else if (destinationType === "international_departure") {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          airport_code_for_international_departure_segment:
            newDestination.airport_code,
        }));
      } else if (destinationType === "international_arrival") {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          airport_code_for_international_arrival_segment:
            newDestination.airport_code,
        }));
      }
    }

    setDestinationType("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      destination_id: destination.destination_id,
      ...formValues,
    };

    startLoading();
    try {
      let apiUrl = "/destination/member/edit-destination-specific-rules";
      if (user?.role === "owner") {
        apiUrl = "/destination/owner/edit-destination-specific-rules";
      } else if (user?.role === "admin") {
        apiUrl = "/destination/admin/edit-destination-specific-rules";
      }

      const response = await fetchPUTRequest(apiUrl, payload);
      if (response.statusCode === 201) {
        // Update the Redux store only if the API call is successful
        const updatedDestinations = destinations.map((dest) =>
          dest.destination_id === destination.destination_id
            ? { ...dest, destination_specific_rules: { ...formValues } }
            : dest
        );
        dispatch(setDestinations(updatedDestinations));
        setToast({
          open: true,
          message: response.Message,
          severity: "success",
        });
        closeModal();
      } else {
        setToast({
          open: true,
          message: response.Message,
          severity: "error",
        });
      }
    } catch (error) {
      setToast({
        open: true,
        message: "An error occurred",
        severity: "error",
      });
    } finally {
      stopLoading();
    }
  };

  // Determine if the Save button should be disabled
  // Determine if the Save button should be disabled
  const isSaveDisabled =
    (formValues.add_segment_on_domestic_departure &&
      !formValues.airport_code_for_domestic_departure_segment) ||
    (formValues.add_segment_on_domestic_arrival &&
      !formValues.airport_code_for_domestic_arrival_segment) ||
    (formValues.add_segment_on_international_departure &&
      !formValues.airport_code_for_international_departure_segment) ||
    (formValues.add_segment_on_international_arrival &&
      !formValues.airport_code_for_international_arrival_segment) ||
    (formValues.add_wait_time_on_arrival &&
      !formValues.minutes_to_add_on_arrival) ||
    (formValues.add_wait_time_on_departure &&
      !formValues.minutes_to_add_on_departure);

  const renderModalContent = () => (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        label="Add Segment on Domestic Departure"
        name="add_segment_on_domestic_departure"
        value={formValues.add_segment_on_domestic_departure}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="Add Segment on Domestic Arrival"
        name="add_segment_on_domestic_arrival"
        value={formValues.add_segment_on_domestic_arrival}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="Add Segment on International Departure"
        name="add_segment_on_international_departure"
        value={formValues.add_segment_on_international_departure}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="Add Segment on International Arrival"
        name="add_segment_on_international_arrival"
        value={formValues.add_segment_on_international_arrival}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="Departure Segment Airport Code"
        name="airport_code_for_domestic_departure_segment"
        value={formValues.airport_code_for_domestic_departure_segment || ""}
        onChange={handleChange}
        select
      >
        {Array.isArray(destinations) &&
          destinations.map((dest, index) => (
            <MenuItem key={index} value={dest.airport_code}>
              {dest.airport_code} - {dest.airport_name}
            </MenuItem>
          ))}
        <MenuItem
          value="+add"
          onClick={() => handleOpenAddDestinationModal("domestic_departure")}
        >
          + Add a Destination
        </MenuItem>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="Arrival Segment Airport Code"
        name="airport_code_for_domestic_arrival_segment"
        value={formValues.airport_code_for_domestic_arrival_segment || ""}
        onChange={handleChange}
        select
      >
        {Array.isArray(destinations) &&
          destinations.map((dest, index) => (
            <MenuItem key={index} value={dest.airport_code}>
              {dest.airport_code} - {dest.airport_name}
            </MenuItem>
          ))}
        <MenuItem
          value="+add"
          onClick={() => handleOpenAddDestinationModal("domestic_arrival")}
        >
          + Add a Destination
        </MenuItem>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="International Departure Segment Airport Code"
        name="airport_code_for_international_departure_segment"
        value={
          formValues.airport_code_for_international_departure_segment || ""
        }
        onChange={handleChange}
        select
      >
        {Array.isArray(destinations) &&
          destinations.map((dest, index) => (
            <MenuItem key={index} value={dest.airport_code}>
              {dest.airport_code} - {dest.airport_name}
            </MenuItem>
          ))}
        <MenuItem
          value="+add"
          onClick={() =>
            handleOpenAddDestinationModal("international_departure")
          }
        >
          + Add a Destination
        </MenuItem>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="International Arrival Segment Airport Code"
        name="airport_code_for_international_arrival_segment"
        value={formValues.airport_code_for_international_arrival_segment || ""}
        onChange={handleChange}
        select
      >
        {Array.isArray(destinations) &&
          destinations.map((dest, index) => (
            <MenuItem key={index} value={dest.airport_code}>
              {dest.airport_code} - {dest.airport_name}
            </MenuItem>
          ))}
        <MenuItem
          value="+add"
          onClick={() => handleOpenAddDestinationModal("international_arrival")}
        >
          + Add a Destination
        </MenuItem>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        label="Add Wait Time on Arrival"
        name="add_wait_time_on_arrival"
        value={formValues.add_wait_time_on_arrival}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </TextField>

      <TextField
        fullWidth
        margin="normal"
        label="Minutes to Add on Arrival"
        name="minutes_to_add_on_arrival"
        value={formValues.minutes_to_add_on_arrival || ""}
        onChange={handleChange}
        type="number"
      />

      <TextField
        fullWidth
        margin="normal"
        label="Add Wait Time on Departure"
        name="add_wait_time_on_departure"
        value={formValues.add_wait_time_on_departure}
        onChange={handleChange}
        select
        SelectProps={{
          native: true,
        }}
      >
        <option value={false}>No</option>
        <option value={true}>Yes</option>
      </TextField>

      <TextField
        fullWidth
        margin="normal"
        label="Minutes to Add on Departure"
        name="minutes_to_add_on_departure"
        value={formValues.minutes_to_add_on_departure || ""}
        onChange={handleChange}
        type="number"
      />
    </form>
  );

  if (!destination) {
    return null; // or a loader/spinner while the data is being fetched
  }

  return (
    <>
      <StyledCard>
        <StyledCardHeader
          title={
            <Typography sx={headerTitleStyle} variant="h6">
              Destination Specific Rules
            </Typography>
          }
          action={
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Edit
            </Button>
          }
        />
        <CardContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Add Segment on Domestic Departure
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .add_segment_on_domestic_departure
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Add Segment on Domestic Arrival
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .add_segment_on_domestic_arrival
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Add Segment on International Departure
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .add_segment_on_international_departure
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Add Segment on International Arrival
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .add_segment_on_international_arrival
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Departure Segment Airport Code
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .airport_code_for_domestic_departure_segment || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Arrival Segment Airport Code
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .airport_code_for_domestic_arrival_segment || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    International Departure Segment Airport Code
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .airport_code_for_international_departure_segment || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    International Arrival Segment Airport Code
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .airport_code_for_international_arrival_segment || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Add Wait Time on Arrival
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .add_wait_time_on_arrival
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Minutes to Add on Arrival
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .minutes_to_add_on_arrival || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Add Wait Time on Departure
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .add_wait_time_on_departure
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1" align="left">
                    Minutes to Add on Departure
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="left">
                    {destination.destination_specific_rules
                      .minutes_to_add_on_departure || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>

      <Modal
        title="Edit Destination Rules"
        open={isModal.open && isModal.type === "editDestinationRules"}
        onClose={handleClose}
        content={renderModalContent()}
        action={
          <CustomButton
            label={"Save"}
            size={"medium"}
            width={"fit-content"}
            onClick={handleSubmit}
            disabled={isSaveDisabled}
            isLoading={isLoading}
            bgColor={"#479DE1"}
          />
        }
      />

      <Dialog
        open={openAddDestination}
        onClose={() => handleCloseAddDestinationModal(null)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add a Destination</DialogTitle>
        <DialogContent>
          <AddDestinationComponent
            onAddDestination={(newDestination) =>
              handleCloseAddDestinationModal(newDestination)
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DestinationRulesCardComponent;
