import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BookingCardComponent from "./BookingCardComponent";
import {
  formatCurrency,
  formatDateLong,
  renderChipColorByStatus,
} from "../../utils/Helper";
import { readableStatus } from "../../utils/Constants";
import Label from "../../components/label";
import { useSelector } from "react-redux";

const BookingDetailComponent = () => {
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState(null);

  const bookingId = location?.state?.booking_id;

  const bookings = useSelector((state) => state.bookings?.bookings);

  // Find the booking in the Redux store using the booking ID
  const booking = [
    ...(bookings?.upcomingBookings || []),
    ...(bookings?.pastBookings || []),
  ].find((b) => b.booking_id === bookingId);

  useEffect(() => {
    if (booking) {
      const items = [
        {
          key: "tail_number",
          label: "Tail Number",
          value: booking?.tail_number,
        },
        {
          key: "booking_reference",
          label: "Booking Reference",
          value: booking?.booking_reference,
        },
        {
          key: "number_of_passengers",
          label: "Number Of Passengers",
          value: booking?.number_of_passengers,
        },
        {
          key: "trip_type",
          label: "Trip Type",
          value: readableStatus(booking?.trip_type),
        },
        {
          key: "trip_departure_airport_code",
          label: "Departure Airport",
          value: booking?.trip_departure_airport_code,
        },
        {
          key: "trip_arrival_airport_code",
          label: "Arrival Airport",
          value: booking?.trip_arrival_airport_code,
        },
        {
          key: "base_price",
          label: "Base Price",
          value: `${formatCurrency(booking?.base_price)}`,
        },

        { key: "discount", label: "Discount", value: booking?.discount },
        {
          key: "total_price",
          label: "Total Price",
          value: `${formatCurrency(booking?.total_price)}`,
        },
        {
          key: "amount_paid",
          label: "Amount Paid",
          value: `${formatCurrency(booking?.amount_paid)}`,
        },
        {
          key: "status",
          label: "Status",
          value: (
            <Label color={renderChipColorByStatus(booking?.status)}>
              {readableStatus(booking?.status)}
            </Label>
          ),
        },
        {
          key: "amount_due_at_time_of_booking",
          label: "Amount Due At Time Of Booking",
          value: `${formatCurrency(booking?.amount_due_at_time_of_booking)}`,
        },
        {
          key: "amount_due_later",
          label: "Amount Due Later",
          value: `${formatCurrency(booking?.amount_due_later)}`,
        },
        {
          key: "amount_due_later_date",
          label: "Amount Due Later Date",
          value: formatDateLong(booking?.amount_due_later_date),
        },
      ];
      setBookingDetails([...items]);
    }
  }, [booking]);

  return (
    <>
      <BookingCardComponent
        title={"Booking Detail"}
        component={
          <Grid container spacing={{ xs: 5, md: 3 }} columns={{ md: 12 }}>
            {bookingDetails?.map((element, i) => (
              <Grid
                item
                key={i}
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="subtitle1" align="center">
                  {element.label}
                </Typography>
                <Typography paragraph align="center">
                  {element?.value ? element.value : "-"}
                </Typography>
              </Grid>
            ))}
          </Grid>
        }
      />
    </>
  );
};

export default BookingDetailComponent;
