// Ground section

// Default
import { useEffect, useState } from "react";

// MUI component
import Box from "@mui/material/Box";

// Redux
import { useSelector } from "react-redux";

// Custom
import FleetDetailCardComponent from "./FleetDetailCardComponent";
import SwitchComponent from "../../switch/SwitchComponent";
import SpinnerComponent from "../../spinner/SpinnerComponent";

// Context
import { useToast } from "../../../context/ToastContext";
import { useLoader } from "../../../context/LoaderContext";

// Utils
import { fetchPUTRequest } from "../../../utils/Services";

const ShowUnshowSectionComponent = () => {
  const [showToCustomerChecked, setshowToCustomerChecked] = useState(false);

  const fleet = useSelector((state) => state.fleet);
  const { setToast } = useToast();
  const { isLoading, startLoading, stopLoading } = useLoader();

  const user = useSelector((state) => state.auth);

  useEffect(() => {
    setshowToCustomerChecked(fleet?.details?.is_show_to_customer);
  }, [fleet]);

  const handleshowToCustomerChange = async () => {
    const payload = {
      tail_number: fleet?.tail_number,
      is_show_to_customer: !showToCustomerChecked,
    };

    let apiUrl = `/fleet/member/show-unshow-plane`;
    if (user?.role === "owner") {
      apiUrl = `/fleet/owner/show-unshow-plane`;
    } else if (user?.role === "admin") {
      apiUrl = `/fleet/admin/show-unshow-plane`;
    }

    const response = await fetchPUTRequest(apiUrl, payload);

    if (response?.statusCode === 200 && response) {
      setToast({
        open: true,
        message: response?.message,
        severity: "success",
      });

      setshowToCustomerChecked(response?.is_show_to_customer);
    } else {
      setToast({
        open: true,
        message: response?.message,
        severity: "error",
      });
    }
  };

  return (
    <FleetDetailCardComponent
      component={
        <Box sx={{ position: "relative" }}>
          {/* {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 2,
              }}
            >
              <SpinnerComponent size={20} show={isLoading} />
            </Box>
          )} */}
          <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
            <SwitchComponent
              label="Show to Customer"
              value={showToCustomerChecked}
              onChange={handleshowToCustomerChange}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default ShowUnshowSectionComponent;
