import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

// Custom
import CustomButton from "../../forms/button/CustomButton";
import SpinnerComponent from "../../components/spinner/SpinnerComponent";
import HelmetComponent from "../../components/helmet/HelmetComponent";

// Utils
import { fetchGETRequest } from "../../utils/Services";
import { EDISPATCHED_HELMET } from "../../utils/Constants";

// Context
import { useLoader } from "../../context/LoaderContext";
import { formatCurrency, formatDate } from "../../utils/Helper";
import { setMaintenanceLogs } from "../../store/features/MaintenanceLogsSlice";
import { useSelector, useDispatch } from "react-redux";
import { viewImageRequest } from "../../utils/Services";

const TABLE_HEAD = [
  { id: "number", label: "#" },
  { id: "date", label: "Date" },
  { id: "tail_number", label: "Tail Number" },
  { id: "hobbs", label: "Hobbs" },
  { id: "performed_by_name", label: "Performed By" },
  { id: "is_annual_inspection", label: "Annual Inspection" },
  { id: "is_oil_change", label: "Oil Change" },
  { id: "is_hundred_hour_inspection", label: "100-Hour Inspection" },
  { id: "cost", label: "Cost" },
  { id: "signature", label: "View Signature" },
  // { id: "details", label: "Details" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MaintenanceLogPage() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState("");
  const { isLoading, startLoading, stopLoading } = useLoader();
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state.maintenanceLogs);

  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    const getMaintenanceLogsData = async () => {
      try {
        let endpoint = `/fleet/member/get-maintenance-logs?page=${
          page + 1
        }&limit=${limit}`;
        if (role === "owner") {
          endpoint = `/fleet/owner/get-maintenance-logs?page=${
            page + 1
          }&limit=${limit}`;
        } else if (role === "admin") {
          endpoint = `/fleet/admin/get-maintenance-logs?page=${
            page + 1
          }&limit=${limit}`;
        }

        const response = await fetchGETRequest(endpoint, {});

        if (response?.statusCode === 200 && response) {
          dispatch(setMaintenanceLogs(response.maintenanceLogs));
          setTotal(response.total);
        } else {
          console.error("Failed to fetch maintenance logs: ", response);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        stopLoading();
      }
    };
    getMaintenanceLogsData();
  }, [page, limit, startLoading, stopLoading]);

  const handleView = (data) => {
    navigate(`/maintenance-logs/${data.maintenance_id}`, { state: data });
  };

  const handleOpen = async (maintenance_id, index) => {
    setLoading(index);
    try {
      let signatureEndpoint = `/fleet/member/download-maintenance-signature/${maintenance_id}`;
      if (role === "owner") {
        signatureEndpoint = `/fleet/owner/download-maintenance-signature/${maintenance_id}`;
      } else if (role === "admin") {
        signatureEndpoint = `/fleet/admin/download-maintenance-signature/${maintenance_id}`;
      }

      const response = await viewImageRequest(signatureEndpoint);
      const url = URL.createObjectURL(response);
      setSignatureUrl(url);
      setOpen(true);
      setLoading(null);
    } catch (error) {
      setLoading(null);
      console.error("Error fetching signature", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSignatureUrl("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <HelmetComponent title={`${EDISPATCHED_HELMET} Maintenance Logs`} />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          mb={5}
        >
          <Typography variant="h4" gutterBottom mb={0}>
            Maintenance Logs
          </Typography>
          <CustomButton
            label="Create Maintenance Log"
            width={"fit-content"}
            sx={{
              width: "auto",
              whiteSpace: "nowrap",
            }}
            onClick={() => navigate("/maintenance-logs/add-maintenance-log")}
          />
        </Stack>

        {isLoading && (
          <Box mt={10}>
            <SpinnerComponent show={isLoading} />
          </Box>
        )}

        {!isLoading && state?.maintenanceLogs.length === 0 && (
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              mt={10}
              fontWeight={500}
              textAlign={"center"}
              color={"Gray"}
            >
              No data available
            </Typography>
          </Box>
        )}

        {!isLoading && state?.maintenanceLogs.length > 0 && (
          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((header) => (
                      <TableCell key={header.id} align="center">
                        {header.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(state?.maintenanceLogs) &&
                    state?.maintenanceLogs?.map((log, index) => (
                      <TableRow hover key={log.maintenance_id}>
                        <TableCell align="center">
                          {page * limit + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(log.date)}
                        </TableCell>
                        <TableCell align="center">{log.tail_number}</TableCell>
                        <TableCell align="center">{log.hobbs}</TableCell>
                        <TableCell align="center">
                          {log.performed_by_name}
                        </TableCell>
                        <TableCell align="center">
                          {log.is_annual_inspection ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {log.is_oil_change ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {log.is_hundred_hour_inspection ? "Yes" : "No"}
                        </TableCell>
                        <TableCell align="center">
                          {log.cost !== null
                            ? formatCurrency(log.cost)
                            : "$0.00"}
                        </TableCell>

                        <TableCell align="center">
                          <CustomButton
                            width={"fit-content"}
                            onClick={() =>
                              handleOpen(log.maintenance_id, index)
                            }
                            label={"View"}
                            size={"small"}
                            isLoading={loading === index}
                          />
                        </TableCell>
                        {/* <TableCell align="center">
                          <CustomButton
                            width={"fit-content"}
                            onClick={() => handleView(log)}
                            label={"View"}
                            size={"small"}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
          <Paper sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Signature
            </Typography>
            {signatureUrl && (
              <img
                src={signatureUrl}
                alt="Signature"
                style={{ width: "100%", height: "auto", marginTop: 16 }}
              />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          </Paper>
        </Modal>
      </Container>
    </>
  );
}
