import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTripDetails: {
    tripType: "",
    departureAirport: "",
    arrivalAirport: "",
    departureDate: null,
    departureTime: "",
    returnDate: null,
    returnTime: "",
  },
  offerPriceResponse: {},
  selectedPlaneDetails: {
    selectedPlaneTailNumber: "",
    available: false,
    passengerTotalFlightDistance: 0,
    passengerTotalFlightDuration: 0,
    crewTotalFlightDistance: 0,
    crewTotalFlightDuration: 0,
    tripBasePrice: 0,
    tripTax: 0,
    baseAmountAtTimeOfBooking: 0,
    taxAtTimeOfBooking: 0,
    totalDueAtTimeOfBooking: 0,
    baseAmountDueLater: 0,
    baseAmountDueLaterDate: null,
    taxDueLater: 0,
    totalDueLater: 0,
    flight_segments: [],
  },
  quotedPrice: {
    tripBasePrice: 0,
    tripTax: 0,
    baseAmountAtTimeOfBooking: 0,
    taxAtTimeOfBooking: 0,
    totalDueAtTimeOfBooking: 0,
    baseAmountDueLater: 0,
    baseAmountDueLaterDate: null,
    taxDueLater: 0,
    totalDueLater: 0,
    flight_segments: [],
  },
  customerDetails: {
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneCountryCode: "",
    phoneNumber: "",
    nationality: "",
    passportNumber: "",
    passportExpiration: "",
    gender: "",
  },
  totalNumberOfPassengers: 1,
  passengerDetails: [],
  loading: false,
  isSearchCompleted: false,
  isSegmentConfirmed: false,
};

const createBookingSlice = createSlice({
  name: "createBooking",
  initialState,
  reducers: {
    setSearchTripDetails: (state, action) => {
      state.searchTripDetails = {
        ...state.searchTripDetails,
        ...action.payload,
      };
    },
    setOfferPriceResponse: (state, action) => {
      state.offerPriceResponse = action.payload;
    },
    setSelectedPlaneTailNumber: (state, action) => {
      state.selectedPlaneDetails.selectedPlaneTailNumber = action.payload;
    },
    setSelectedPlaneDetails: (state, action) => {
      state.selectedPlaneDetails = {
        selectedPlaneTailNumber:
          state.selectedPlaneDetails.selectedPlaneTailNumber,
        ...action.payload,
      };
    },
    setQuotedPrice: (state, action) => {
      state.quotedPrice = action.payload;
    },
    setCustomerDetails: (state, action) => {
      state.customerDetails = { ...state.customerDetails, ...action.payload };
    },
    setTotalNumberOfPassengers: (state, action) => {
      state.totalNumberOfPassengers = action.payload;
    },
    addPassengerDetails: (state, action) => {
      state.passengerDetails.push(action.payload);
      state.totalNumberOfPassengers += 1;
    },
    updatePassengerDetails: (state, action) => {
      const { index, details } = action.payload;
      state.passengerDetails[index] = {
        ...state.passengerDetails[index],
        ...details,
      };
    },
    deletePassengerDetails: (state, action) => {
      state.passengerDetails = state.passengerDetails.filter(
        (_, index) => index !== action.payload
      );
      state.totalNumberOfPassengers -= 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSearchCompleted: (state, action) => {
      state.isSearchCompleted = action.payload;
    },
    setIsSegmentConfirmed: (state, action) => {
      state.isSegmentConfirmed = action.payload;
    },
    resetBooking: (state) => {
      state.customerDetails = {
        customerId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneCountryCode: "",
        phoneNumber: "",
        nationality: "",
        passportNumber: "",
        passportExpiration: "",
        gender: "",
      };
      state.totalNumberOfPassengers = 1;
      state.passengerDetails = [];
    },
  },
});

export const {
  setSearchTripDetails,
  setOfferPriceResponse,
  setSelectedPlaneTailNumber,
  setSelectedPlaneDetails,
  setQuotedPrice,
  setCustomerDetails,
  setTotalNumberOfPassengers,
  addPassengerDetails,
  updatePassengerDetails,
  deletePassengerDetails,
  setLoading,
  setSearchCompleted,
  setIsSegmentConfirmed,
  resetBooking,
} = createBookingSlice.actions;

export default createBookingSlice.reducer;
