import Container from "@mui/material/Container";

// Custom
import DetailsSectionComponent from "./DetailsSectionComponent";
import DocumentsSectionComponent from "./DocumentsSectionComponent";
import HobbsAndTachSectionsComponent from "./HobbsAndTachSectionComponent";

// Redux
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SpinnerComponent from "../../../components/spinner/SpinnerComponent";
import { useLoader } from "../../../context/LoaderContext";
import {
  setAirworthinessDirectives,
  setFleetDetails,
  setInspections,
  setMaintenanceLogs,
  setTailNumber,
} from "../../../store/features/FleetSlice";
import { fetchGETRequest } from "../../../utils/Services";
import EngineOneCardComponent from "./EngineOneCardComponent";
import InspectionsCardComponent from "./InspectionsCardComponent";
import EngineTwoCardComponent from "./EngineTwoCardComponent";
import PropellerOneCardComponent from "./PropellerOneCardComponent";
import PropellerTwoCardComponent from "./PropellerTwoCardComponent";
import OtherInspectionCardComponent from "./OtherInspectionCardComponent";
import ShowUnshowSectionComponent from "./ShowUnShowSectionComponent";
import GroundSectionComponent from "./GroundSectionComponent";

const FleetDetailComponent = () => {
  const params = useLocation();
  const fleet = useSelector((state) => state.fleet);
  const { isLoading, startLoading, stopLoading } = useLoader();
  const dispatch = useDispatch();

  // Get user role from Redux store
  const role = useSelector((state) => state.auth.role);

  const getPlanData = async () => {
    startLoading();
    const tailNumber = params?.state;
    try {
      let endpoint = `/fleet/member/get-plane/${tailNumber}`;
      if (role === "admin") {
        endpoint = `/fleet/admin/get-plane/${tailNumber}`;
      } else if (role === "owner") {
        endpoint = `/fleet/owner/get-plane/${tailNumber}`;
      }

      const response = await fetchGETRequest(endpoint, {});
      if (response?.statusCode === 200 && response) {
        const {
          airworthiness_directives,
          maintenance_logs,
          inspections,
          ...rest
        } = response?.data;
        dispatch(setTailNumber(tailNumber));
        dispatch(setFleetDetails(rest));
        dispatch(setMaintenanceLogs(maintenance_logs));
        dispatch(setAirworthinessDirectives(airworthiness_directives));
        dispatch(setInspections(inspections));
        stopLoading();
      } else {
        stopLoading();
      }
    } catch (error) {
      stopLoading();
    }
  };

  useEffect(() => {
    getPlanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <Container maxWidth="xl">
      {isLoading && !fleet?.details ? (
        <SpinnerComponent show={isLoading} />
      ) : (
        <>
          <Grid container spacing={2}>
            {role !== "member" && (
              <>
                <Grid item xs={6}>
                  <ShowUnshowSectionComponent />
                </Grid>
                <Grid item xs={6}>
                  <GroundSectionComponent />
                </Grid>
              </>
            )}
          </Grid>

          <DetailsSectionComponent />
          <DocumentsSectionComponent />
          <HobbsAndTachSectionsComponent />
          <InspectionsCardComponent />
          <EngineOneCardComponent />
          <EngineTwoCardComponent />
          <PropellerOneCardComponent />
          <PropellerTwoCardComponent />
          <OtherInspectionCardComponent />
        </>
      )}
    </Container>
  );
};

export default FleetDetailComponent;
