import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { setBookings } from "../../store/features/BookingsSlice";
import PaymentCardComponent from "./PaymentCardComponent";
import CustomButton from "../../forms/button/CustomButton";
import { fetchGETRequest } from "../../utils/Services";
import { useLoader } from "../../context/LoaderContext";
import {
  formatCurrency,
  formatDateTimeLong,
  formatPhoneNumber,
  renderChipColorByStatus,
} from "../../utils/Helper";
import { readableStatus } from "../../utils/Constants";
import Label from "../label";
import { useToast } from "../../context/ToastContext";

const PaymentDetailComponent = () => {
  const params = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const { toast, setToast } = useToast();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [bookingFound, setBookingFound] = useState(false); // New state to track if booking is found after re-render
  const [triggerCheck, setTriggerCheck] = useState(false); // State to trigger re-check after state update

  const bookings = useSelector((state) => state.bookings?.bookings || {});
  const bookingId = params?.state?.booking_id;

  const user = useSelector((state) => state.auth);

  const findBookingInRedux = () => {
    const { upcomingBookings, pastBookings } = bookings;
    const allBookings = [...(upcomingBookings || []), ...(pastBookings || [])];
    return allBookings.find((item) => item?.booking_id === bookingId);
  };

  const getBookingsData = async () => {
    startLoading();

    try {
      let foundBooking = findBookingInRedux();

      if (foundBooking) {
        setBookingFound(true); // Update state if booking is found
        stopLoading();
        return;
      }

      let apiUrl = `/booking/member/get-bookings`;
      if (user?.role === "owner") {
        apiUrl = `/booking/owner/get-bookings`;
      } else if (user?.role === "admin") {
        apiUrl = `/booking/admin/get-bookings`;
      }

      const response = await fetchGETRequest(apiUrl, {});

      if (response?.statusCode === 200 && response) {
        dispatch(
          setBookings({
            upcomingBookings: response?.upcomingBookings,
            pastBookings: response?.pastBookings,
          })
        );

        // Trigger a re-check in a separate render cycle
        setTriggerCheck(true);
      } else {
        console.error("Error fetching bookings:", response);
      }
    } catch (error) {
      console.error("Error getting booking data:", error);
    } finally {
      stopLoading();
    }
  };

  // Handle booking details
  const handleBookingDetails = () => {
    const foundBooking = findBookingInRedux();

    if (foundBooking) {
      setBookingFound(true); // Update state if booking is found
    } else {
      getBookingsData();
    }
  };

  // Re-check Redux state after triggering a re-render
  useEffect(() => {
    if (triggerCheck) {
      const foundBooking = findBookingInRedux();

      if (foundBooking) {
        setBookingFound(true);
      } else {
        setToast({
          open: true,
          message: "Booking not found",
          severity: "error",
        });
      }
      setTriggerCheck(false); // Reset the trigger
    }
  }, [triggerCheck, bookings]); // Ensure this effect runs after the state has been updated

  // Navigate when booking is found
  useEffect(() => {
    if (bookingFound && bookingId) {
      navigate(`/bookings/${bookingId}`, {
        state: { booking_id: bookingId },
      });
    }
  }, [bookingFound, bookingId, navigate]);

  // Set initial payment details
  useEffect(() => {
    if (params?.state?.booking_id) {
      setPaymentDetails(params?.state);
    } else {
      setToast({
        open: true,
        message: "Booking ID not found",
        severity: "error",
      });
    }
  }, [params, setToast]);

  return (
    <>
      <PaymentCardComponent
        title={"Detail"}
        component={
          <Grid container spacing={{ xs: 5, md: 3 }} columns={{ md: 12 }}>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Customer Name
              </Typography>
              <Typography paragraph align="center">
                {`${paymentDetails?.customer?.first_name} ${paymentDetails?.customer?.last_name}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Email
              </Typography>
              <Typography paragraph align="center">
                {paymentDetails?.customer?.email || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Card Number
              </Typography>
              <Typography paragraph align="center">
                {` **** ${paymentDetails?.processor_details?.card_last_four}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Phone Number
              </Typography>
              <Typography paragraph align="center">
                {formatPhoneNumber(paymentDetails?.customer?.phone_number) ||
                  "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Base
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(paymentDetails?.amount) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Tax
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(paymentDetails?.tax) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Total Amount
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(paymentDetails?.amount + paymentDetails?.tax) ||
                  "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Status
              </Typography>
              <Typography paragraph align="center">
                <Label color={renderChipColorByStatus(paymentDetails?.status)}>
                  {readableStatus(paymentDetails?.status)}
                </Label>
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Transaction Time
              </Typography>
              <Typography paragraph align="center">
                {formatDateTimeLong(paymentDetails?.created_at) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Platform Fee
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(paymentDetails?.platform_fee) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Net
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(paymentDetails?.net_amount_to_business) || "-"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Booking Details
              </Typography>
              <CustomButton
                width={"fit-content"}
                onClick={handleBookingDetails}
                label={"Go"}
                isLoading={isLoading}
                size={"small"}
              />
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default PaymentDetailComponent;
