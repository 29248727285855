import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Modal from "../../modal/Modal";
import CustomInput from "../../../forms/input/CustomInput";
import CustomButton from "../../../forms/button/CustomButton";
import SwitchComponent from "../../switch/SwitchComponent";
import { useFormik } from "formik";
import { useModal } from "../../../context/ModalContext";
import { useToast } from "../../../context/ToastContext";
import { useLoader } from "../../../context/LoaderContext";
import { fetchPUTRequest } from "../../../utils/Services";
import { useSelector, useDispatch } from "react-redux";
import { setDestinations } from "../../../store/features/DestinationsSlice";
import { US_STATES } from "../../../utils/StatesEnum";
import { COUNTRIES } from "../../../utils/CountriesEnum";
import { TIME_ZONE } from "../../../utils/Constants";

const DestinationDetailsModal = ({ destinationDetails }) => {
  const { isModal, closeModal } = useModal();
  const { setToast } = useToast();
  const dispatch = useDispatch();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const state = useSelector((state) => state.destinations);
  const user = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      city: "",
      state: "",
      country: "",
      airport_name: "",
      destination_specific_cost: "",
      status: "",
      airport_timezone: "",
      show_in_arrival_list: false,
      show_in_departure_list: false,
      airport_longitude: "",
      airport_latitude: "",
    },

    onSubmit: async (values) => {
      startLoading();

      const newPayload = { ...values };
      newPayload["destination_id"] = destinationDetails?.destination_id;
      newPayload["destination_specific_cost"] =
        newPayload.destination_specific_cost * 100;

      let apiUrl = `/destination/member/edit-destination`;
      if (user?.role === "owner") {
        apiUrl = `/destination/owner/edit-destination`;
      } else if (user?.role === "admin") {
        apiUrl = `/destination/admin/edit-destination`;
      }

      const response = await fetchPUTRequest(apiUrl, newPayload);

      if (response?.statusCode === 201 && response) {
        setToast({
          open: true,
          message: response?.Message,
          severity: "success",
        });

        // Directly update the Redux state without mutating the input prop
        const updatedDestinations = state?.destinations?.map((destination) => {
          if (
            destination?.destination_id === destinationDetails?.destination_id
          ) {
            return { ...destination, ...response?.data }; // Merge the existing and new data
          }
          return destination;
        });

        dispatch(setDestinations(updatedDestinations));

        stopLoading();
        closeModal(); // Ensure this is after state update for a smooth UI transition
        formik.resetForm();
      } else {
        setToast({
          open: true,
          message: response?.Message,
          severity: "error",
        });
        stopLoading();
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (destinationDetails) {
      const formattedDestinationCost =
        destinationDetails?.destination_specific_cost / 100;
      formik.setFieldValue("city", destinationDetails?.city);
      formik.setFieldValue("state", destinationDetails?.state);
      formik.setFieldValue("country", destinationDetails?.country);
      formik.setFieldValue("airport_name", destinationDetails?.airport_name);
      formik.setFieldValue(
        "destination_specific_cost",
        formattedDestinationCost
      );
      formik.setFieldValue("status", destinationDetails?.status);
      formik.setFieldValue(
        "airport_timezone",
        destinationDetails?.airport_timezone
      );
      formik.setFieldValue(
        "show_in_arrival_list",
        destinationDetails?.show_in_arrival_list
      );
      formik.setFieldValue(
        "show_in_departure_list",
        destinationDetails?.show_in_departure_list
      );
      formik.setFieldValue(
        "airport_longitude",
        destinationDetails?.airport_longitude
      );
      formik.setFieldValue(
        "airport_latitude",
        destinationDetails?.airport_latitude
      );
    }
  }, [destinationDetails]);

  return (
    <Modal
      title={"Update Details"}
      open={isModal.open && isModal.type === "destinationDetail"}
      content={
        <Stack display={"flex"} flexDirection={"column"} gap={5}>
          <CustomInput
            name="city"
            label="City"
            value={formik.values.city}
            onChange={formik.handleChange}
            required
          />
          <TextField
            select
            name="country"
            label="Country"
            value={formik.values.country}
            onChange={(e) => {
              formik.handleChange(e);
              // Reset state field if country changes
              if (e.target.value !== "United States") {
                formik.setFieldValue("state", "");
              }
            }}
            required
          >
            {COUNTRIES.map((country, index) => (
              <MenuItem key={index} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
          {formik.values.country === "United States" ? (
            <TextField
              select
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              required
            >
              {US_STATES.map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <CustomInput
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
            />
          )}
          <CustomInput
            name="airport_name"
            value={formik.values.airport_name}
            label="Airport Name"
            onChange={formik.handleChange}
            required
          />
          <CustomInput
            type="number"
            name="destination_specific_cost"
            value={formik.values.destination_specific_cost}
            label="Destination Specific Cost"
            onChange={formik.handleChange}
            required
          />
          <TextField
            select
            name="status"
            label="Status"
            value={formik.values.status}
            onChange={formik.handleChange}
            required
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="retired">Retired</MenuItem>
          </TextField>
          <TextField
            select
            name="airport_timezone"
            label="Airport Timezone"
            value={formik.values.airport_timezone}
            onChange={formik.handleChange}
            required
          >
            {TIME_ZONE.map((timezone, index) => (
              <MenuItem key={index} value={timezone.value}>
                {timezone.label}
              </MenuItem>
            ))}
          </TextField>
          <SwitchComponent
            value={formik.values.show_in_arrival_list}
            label={"Show in Arrival List (Customer App)"}
            onChange={() =>
              formik.setFieldValue(
                "show_in_arrival_list",
                !formik.values.show_in_arrival_list
              )
            }
          />
          <SwitchComponent
            value={formik.values.show_in_departure_list}
            label={"Show in Departure List (Customer App)"}
            onChange={() =>
              formik.setFieldValue(
                "show_in_departure_list",
                !formik.values.show_in_departure_list
              )
            }
          />
          <CustomInput
            name="airport_longitude"
            label="Airport Longitude"
            value={formik.values.airport_longitude}
            onChange={formik.handleChange}
            required
            type="number"
          />
          <CustomInput
            name="airport_latitude"
            label="Airport Latitude"
            value={formik.values.airport_latitude}
            onChange={formik.handleChange}
            required
            type="number"
          />
        </Stack>
      }
      action={
        <CustomButton
          label={"Update"}
          size={"medium"}
          width={"fit-content"}
          onClick={formik.handleSubmit}
          disabled={isLoading}
          isLoading={isLoading}
          bgColor={"#479DE1"}
        />
      }
    />
  );
};

export default DestinationDetailsModal;
