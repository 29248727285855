import React from "react";
import { useSelector } from "react-redux";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Container, Stack, styled } from "@mui/system";
import Box from "@mui/material/Box";
import { formatCurrency, formatDateLong } from "../../utils/Helper";
import { Divider } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  border: "1px solid #ddd",
  margin: "20px 0",
  boxShadow: "none",
  borderRadius: "10px",
  padding: "20px",
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  background: "#f2f5f7",
  borderBottom: "1px solid #ddd",
  padding: "15px 25px",
}));

const headerTitleStyle = {
  alignItems: "center",
  display: "flex",
};

const BookingPriceCardComponent = () => {
  const quotedPrice = useSelector((state) => state.createBooking.quotedPrice);

  return (
    <StyledCard>
      <StyledCardHeader
        title={
          <Typography sx={headerTitleStyle} variant={"h6"}>
            Quoted Price
          </Typography>
        }
      />
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Container maxWidth="sm">
          <Stack spacing={1.5}>
            <Typography variant="h4">Trip Total Cost Breakdown</Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">Trip Base Price:</Typography>
              <Typography>
                {formatCurrency(quotedPrice.tripBasePrice)}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">Trip Tax:</Typography>
              <Typography>{formatCurrency(quotedPrice.tripTax)}</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Trip Grand Total:
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {formatCurrency(quotedPrice.tripTotal)}
              </Typography>
            </Box>
          </Stack>

          {quotedPrice.baseAmountDueLater > 0 && (
            <>
              <Divider sx={{ my: 5 }} />

              <Stack spacing={1.5}>
                <Typography variant="h5">
                  Following amount will be charged now
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">
                    Base Amount Due at Time of Booking:
                  </Typography>
                  <Typography>
                    {formatCurrency(quotedPrice.baseAmountAtTimeOfBooking)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">
                    Tax Due at Time of Booking:
                  </Typography>
                  <Typography>
                    {formatCurrency(quotedPrice.taxAtTimeOfBooking)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Total Due at Time of Booking:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {formatCurrency(quotedPrice.totalDueAtTimeOfBooking)}
                  </Typography>
                </Box>
              </Stack>

              <Divider sx={{ my: 5 }} />

              <Stack spacing={1.5}>
                <Typography variant="h5">
                  Following amount will be charged later
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">
                    Base Amount Due Later:
                  </Typography>
                  <Typography>
                    {formatCurrency(quotedPrice.baseAmountDueLater)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">Tax Due Later:</Typography>
                  <Typography>
                    {formatCurrency(quotedPrice.taxDueLater)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">Total Due Later:</Typography>
                  <Typography>
                    {formatCurrency(
                      quotedPrice.baseAmountDueLater + quotedPrice.taxDueLater
                    )}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1">Due Later Date:</Typography>
                  <Typography>
                    {formatDateLong(quotedPrice.baseAmountDueLaterDate)}
                  </Typography>
                </Box>
              </Stack>
            </>
          )}
        </Container>
      </CardContent>
    </StyledCard>
  );
};

export default BookingPriceCardComponent;
