import React from "react";
import { useFormik, FormikProvider, Form } from "formik";
import { Grid } from "@mui/material";
import Modal from "../../../modal/Modal";
import CustomInput from "../../../../forms/input/CustomInput";
import CustomDatePicker from "../../../../forms/datePicker/CustomDatePicker";
import CustomButton from "../../../../forms/button/CustomButton";
import { useModal } from "../../../../context/ModalContext";
import { useLoader } from "../../../../context/LoaderContext";
import { useToast } from "../../../../context/ToastContext";
import { fetchPUTRequest } from "../../../../utils/Services";
import { useDispatch, useSelector } from "react-redux";
import { updateEngineTwoInspections } from "../../../../store/features/FleetSlice";

const EngineTwoDetailModalComponent = ({ section }) => {
  const { isModal, closeModal } = useModal();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const { setToast } = useToast();
  const dispatch = useDispatch();
  const fleet = useSelector((state) => state.fleet);
  const user = useSelector((state) => state.auth);

  // Get the section data from Redux
  const sectionData =
    fleet?.inspections?.[0]?.engine_two_inspections?.[section] || {};

  const formik = useFormik({
    initialValues: sectionData,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      startLoading();

      const updatedValues = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== formik.initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      const formattedValues = {
        engine_two_inspections: {
          [section]: updatedValues,
        },
        tail_number: fleet.tail_number,
      };

      let apiUrl = `/fleet/member/update-plane-inspections`;

      if (user?.role === "owner") {
        apiUrl = `/fleet/owner/update-plane-inspections`;
      } else if (user?.role === "admin") {
        apiUrl = `/fleet/admin/update-plane-inspections`;
      }

      try {
        const response = await fetchPUTRequest(apiUrl, formattedValues);

        if (response?.statusCode === 200 && response) {
          setToast({
            open: true,
            message: response?.Message,
            severity: "success",
          });

          dispatch(updateEngineTwoInspections({ [section]: updatedValues }));
          closeModal();
        } else {
          setToast({
            open: true,
            message: response?.Message || "Failed to update",
            severity: "error",
          });
        }
      } catch (error) {
        setToast({
          open: true,
          message: "An error occurred",
          severity: "error",
        });
      } finally {
        stopLoading();
        setSubmitting(false);
      }
    },
  });

  const handleUpdate = () => {
    formik.handleSubmit();
  };

  return (
    <Modal
      title={`Update Engine Two ${section
        .replace(/_/g, " ")
        .replace(/\b\w/g, (l) => l.toUpperCase())}`}
      open={
        isModal.open &&
        isModal.type ===
          `EngineTwo${section
            .replace(/_/g, "")
            .replace(/\b\w/g, (l) => l.toUpperCase())}`
      }
      content={
        <FormikProvider value={formik}>
          <Form id="engine-two-form">
            <Grid container spacing={2}>
              {Object.keys(sectionData).map((field) => (
                <Grid item xs={6} key={field}>
                  {field.includes("date") ? (
                    <CustomDatePicker
                      name={field}
                      label={field.replace(/_/g, " ")}
                      value={formik.values[field]}
                      onChange={(value) => formik.setFieldValue(field, value)}
                    />
                  ) : (
                    <CustomInput
                      name={field}
                      label={field.replace(/_/g, " ")}
                      onChange={formik.handleChange}
                      value={formik.values[field]}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Form>
        </FormikProvider>
      }
      action={
        <>
          <CustomButton
            label="Update"
            size="medium"
            width="fit-content"
            disabled={isLoading || formik.isSubmitting}
            bgColor="#479DE1"
            isLoading={isLoading || formik.isSubmitting}
            onClick={handleUpdate}
            type="button"
          />
          <CustomButton
            label="Cancel"
            size="medium"
            width="fit-content"
            bgColor="#cccccc"
            onClick={closeModal}
            type="button"
          />
        </>
      }
    />
  );
};

export default EngineTwoDetailModalComponent;
