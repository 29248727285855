import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DestinationCardComponent from "./DestinationCardComponent";
import CustomButton from "../../forms/button/CustomButton";
import DestinationDetailsModal from "./destinationModal/DestinationDetailModal";
import { useSelector } from "react-redux";
import { useModal } from "../../context/ModalContext";
import { formatCurrency, capitalizeFirstLetter } from "../../utils/Helper";

const DestinationDetailComponent = () => {
  const { destination_id } = useParams();
  const { openModal } = useModal();

  // Fetch the destination details from the Redux store using the destination_id
  const destinationDetails = useSelector((state) =>
    state?.destinations?.destinations?.find(
      (destination) => destination.destination_id == destination_id
    )
  );

  // Optional: Handle case where destinationDetails is null or undefined
  if (!destinationDetails) {
    return (
      <Typography variant="h6" align="center" color="error">
        Destination not found.
      </Typography>
    );
  }

  return (
    <>
      <DestinationCardComponent
        title={`${destinationDetails.city}, ${destinationDetails.state}`}
        action={
          <CustomButton
            label={"Edit"}
            size={"medium"}
            disabled={false}
            bgColor={"#479DE1"}
            onClick={() => openModal("destinationDetail")}
          />
        }
        component={
          <Grid container spacing={{ xs: 5, md: 3 }} columns={{ md: 9 }}>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Airport Name
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.airport_name || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                City
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.city || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                State
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.state || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Country
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.country || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Status
              </Typography>
              <Typography paragraph align="center">
                {capitalizeFirstLetter(destinationDetails.status) || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Destination Specific Cost
              </Typography>
              <Typography paragraph align="center">
                {formatCurrency(destinationDetails.destination_specific_cost)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Airport Timezone
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.airport_timezone || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Show in Arrival List (Customer App)
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.show_in_arrival_list ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Show in Departure List (Customer App)
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.show_in_departure_list ? "Yes" : "No"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Airport Longitude
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.airport_longitude || "-"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="subtitle1" align="center">
                Airport Latitude
              </Typography>
              <Typography paragraph align="center">
                {destinationDetails.airport_latitude || "-"}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <DestinationDetailsModal destinationDetails={destinationDetails} />
    </>
  );
};

export default DestinationDetailComponent;
