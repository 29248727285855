import React from "react";

import logo from "../../assets/images/logo-1024.png";
import HelmetComponent from "../../components/helmet/HelmetComponent";
import { Container, styled } from "@mui/system";
import { Typography } from "@mui/material";
import MemberAccountSetupComponent from "../../components/auth/MemberAccountSetUpComponent";
import { EDISPATCHED_HELMET } from "../../utils/Constants";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

function CompleteAccountCreationPage() {
  return (
    <React.Fragment>
      <HelmetComponent title={`${EDISPATCHED_HELMET} Complete Account Setup`} />

      <StyledContent>
        <Container>
          <img src={logo} alt="Logo" style={{ width: "300px" }} />
          <Typography variant="h4" gutterBottom>
            Complete Account Setup
          </Typography>
          <MemberAccountSetupComponent />
        </Container>
      </StyledContent>
    </React.Fragment>
  );
}

export default CompleteAccountCreationPage;
