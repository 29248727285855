// User view component

// MUI components
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// Custom
import UserDetailComponent from "./UserDetailComponent";
import UserDocumentsComponent from "./UserDocumentsComponent";
import BackButton from "../backButton/BackButton";

const UserViewComponent = () => {
  return (
    <Container maxWidth="lg">
      <Box mb={3}>
        <BackButton title={"User Details"} />
      </Box>
      <UserDetailComponent />
      {/* <UserDocumentsComponent /> */}
    </Container>
  );
};

export default UserViewComponent;
