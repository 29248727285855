import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI components
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

// Custom components
import CustomInput from "../../forms/input/CustomInput";
import CustomButton from "../../forms/button/CustomButton";
import SwitchComponent from "../switch/SwitchComponent";
import BackButton from "../backButton/BackButton";

// Context
import { useToast } from "../../context/ToastContext";
import { useLoader } from "../../context/LoaderContext";
import { fetchPOSTRequest } from "../../utils/Services";
import { useSelector } from "react-redux";

const AddUserComponent = () => {
  const { setToast } = useToast();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "member",
    is_admin: false,
    is_pilot: false,
    is_anp: false,
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSwitchChange = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();

    let apiUrl = `/user/member/add-user`;
    if (user?.role === "owner") {
      apiUrl = `/user/owner/add-user`;
    } else if (user?.role === "admin") {
      apiUrl = `/user/admin/add-user`;
    }

    const response = await fetchPOSTRequest(apiUrl, formData);

    if (response?.statusCode === 201 && response) {
      setToast({
        open: true,
        message: response?.message,
        severity: "success",
      });
      navigate(-1);
    } else {
      setToast({
        open: true,
        message: response?.message,
        severity: "error",
      });
    }

    stopLoading();
  };

  const isFormValid =
    formData.first_name && formData.last_name && formData.email;

  return (
    <>
      <Box mb={3}>
        <BackButton title={"Add User"} />
      </Box>
      <Container maxWidth="sm">
        <form>
          <Stack spacing={3}>
            <CustomInput
              name="first_name"
              value={formData.first_name}
              label="First Name"
              onChange={handleInputChange}
              required={true}
            />
            <CustomInput
              name="last_name"
              value={formData.last_name}
              label="Last Name"
              onChange={handleInputChange}
              required={true}
            />
            <CustomInput
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleInputChange}
              required={true}
            />

            <SwitchComponent
              value={formData.is_admin}
              label={"Admin"}
              onChange={() => handleSwitchChange("is_admin")}
            />

            <SwitchComponent
              value={formData.is_pilot}
              label={"Pilot"}
              onChange={() => handleSwitchChange("is_pilot")}
            />

            <SwitchComponent
              value={formData.is_anp}
              label={"Mechanic (A&P)"}
              onChange={() => handleSwitchChange("is_anp")}
            />

            <CustomButton
              label={"Add User"}
              size={"large"}
              onClick={handleSubmit}
              disabled={!isFormValid}
              bgColor={"#479DE1"}
              isLoading={isLoading}
            />
          </Stack>
        </form>
      </Container>
    </>
  );
};

export default AddUserComponent;
