// Forget password 

// MUI components
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

// MUI styles
import { styled } from "@mui/material/styles";

// Custom
import CustomButton from "../../forms/button/CustomButton";
import CustomInput from "../../forms/input/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoader } from "../../context/LoaderContext";
import { useToast } from "../../context/ToastContext";
import { fetchPOSTRequest, fetchPUTRequest } from "../../utils/Services";
import { useNavigate } from "react-router-dom";


const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 580,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const ForgetPassword = () => {
  const { isLoading, startLoading, stopLoading } = useLoader();
  const { setToast } = useToast();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .email("Enter valid email."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        email: values.email
      }
      startLoading();
      try {
        const response = await fetchPOSTRequest(
          "/auth/forgot-password-email",
          payload
        );
        if (response.statusCode === 200) {
          setToast({
            open: true,
            message: response.message,
            severity: "success",
          });
          setTimeout(() => navigate("/login"), 5000);
        } else {
          setToast({
            open: true,
            message: response.message,
            severity: "error",
          });
        }
        formik.resetForm();
      } catch (error) {
        setToast({
          open: true,
          message: "An error occurred",
          severity: "error",
        });
      } finally {
        stopLoading();
      }
    },
  });

  return (
    <StyledContent>
      <Stack spacing={3}>
        <CustomInput
          name="email"
          label="Email address"
          value={formik.values.email}
          onChange={formik.handleChange}
          formik={formik}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 2 }}
      ></Stack>
      <CustomButton
        label={"Email reset password link"}
        size={"large"}
        bgColor={"#479DE1"}
        onClick={formik.handleSubmit}
        isLoading={isLoading}
      />
      <Stack alignItems="left" justifyContent="flex-end" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" href="/login">
          Back to Login
        </Link>
        <Link variant="subtitle2" underline="hover" href="/reset-forgot-password">
          Reset Forgot Password
        </Link>
      </Stack>
    </StyledContent>
  )
}

export default ForgetPassword;