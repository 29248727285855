import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Snackbar,
  Alert,
  Link,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CustomIconComponent from "../../components/icon/CustomIconComponent";
import LOGO from "../../assets/images/logo-1024.png";
import { Container, styled } from "@mui/system";
import { Helmet } from "react-helmet-async";
import CustomInput from "../../forms/input/CustomInput";
import { useLoader } from "../../context/LoaderContext";
import { useToast } from "../../context/ToastContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../forms/button/CustomButton";
import { fetchPUTRequest } from "../../utils/Services";

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));
function ResetForgottenPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { email, userId, token } = useParams();
  const { setToast } = useToast();
  const { isLoading, startLoading, stopLoading } = useLoader();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .email("Enter valid email."),
    new_password: Yup.string()
      .required("Password is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one symbol, upper and lower case letters, and a number"
      ),
    confirm_password: Yup.string()
      .required("Confirm password is required.")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      new_password: "",
      confirm_password: "",
      forgot_password_token: "",
      user_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newPayload = {
        email: values.email,
        new_password: values.new_password,
        forgot_password_token: token,
        user_id: userId,
      };

      const response = await fetchPUTRequest(
        `/auth/forgot-password-reset`,
        newPayload
      );
      if (response?.statusCode === 200 && response) {
        setToast({
          open: true,
          message: response?.message,
          severity: "success",
        });
        stopLoading();
        navigate(`/login`);
        formik.resetForm();
      } else {
        setToast({
          open: true,
          message: response?.message,
          severity: "error",
        });
        stopLoading();
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("email", email);
  }, []);

  return (
    <>
      <Helmet>
        <title>eDispatched | Complete Account Creation</title>
      </Helmet>
      <StyledContent>
        <Container>
          <img src={LOGO} alt="Logo" style={{ width: "50px" }} />
          <Typography variant="h4" gutterBottom>
            Reset password
          </Typography>

          <Stack spacing={3}>
            <CustomInput
              name="email"
              label="Email"
              formik={formik}
              readOnly={true}
              value={formik.values.email}
            />
            <CustomInput
              name="new_password"
              label="Password"
              type={showPassword ? "text" : "password"}
              icon={
                <CustomIconComponent
                  icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                />
              }
              onIconClick={() => setShowPassword(!showPassword)}
              value={formik.values.new_password}
              onChange={formik.handleChange}
              formik={formik}
            />
            <CustomInput
              name="confirm_password"
              label="Confirm Password*"
              type={showConfirmPassword ? "text" : "password"}
              icon={
                <CustomIconComponent
                  icon={
                    showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                  }
                />
              }
              onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              formik={formik}
            />
          </Stack>
          <CustomButton
            sx={{ mt: 3 }}
            label={"Reset Password"}
            size={"large"}
            bgColor={"#479DE1"}
            onClick={formik.handleSubmit}
            isLoading={isLoading}
          />
          <Stack alignItems="left" justifyContent="flex-end" sx={{ my: 2 }}>
            <Link variant="subtitle2" underline="hover" href="/login">
              Back to Login
            </Link>
          </Stack>
        </Container>
      </StyledContent>
    </>
  );
}

export default ResetForgottenPassword;
