// Destinations slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  destinations: [],
};

const destinations = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    setDestinations: (state, action) => {
      state.destinations = action.payload;
    },
    resetDestinations: (state) => {
      state.destinations = initialState.destinations;
    },
    setDestinationRules: (state, action) => {
      const { destination_id, rules } = action.payload;
      const destinationIndex = state.destinations.findIndex(
        (destination) => destination.destination_id === destination_id
      );
      if (destinationIndex !== -1) {
        state.destinations[destinationIndex].destination_specific_rules = rules;
      }
    },
  },
});

export const { setDestinations, resetDestinations, setDestinationRules } =
  destinations.actions;
export default destinations.reducer;
