import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CustomIconComponent from "../icon/CustomIconComponent";
import { BASE_API_URL } from "../../utils/Constants";
import { useToast } from "../../context/ToastContext";

function MemberAccountSetupComponent() {
  const { email, token, userId, tenantId } = useParams();

  const [formData, setFormData] = useState({
    email: email,
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { password, confirmPassword } = formData;

  const navigate = useNavigate();
  const { toast, setToast } = useToast();

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      email: email,
    }));
  }, [email]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  function validatePassword() {
    const { password, confirmPassword } = formData;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/;

    if (!password || !confirmPassword) {
      return false;
    } else if (password !== confirmPassword) {
      return false;
    } else if (!regex.test(password)) {
      return false;
    } else {
      return true;
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setToast({
        open: true,
        message: "Password is required",
        severity: "error",
      });
    } else if (password !== confirmPassword) {
      setToast({
        open: true,
        message: "Passwords don't match",
        severity: "error",
      });
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
      setToast({
        open: true,
        message:
          "Password should be at least 8 characters long and contain one uppercase and one lowercase letter",
        severity: "error",
      });
    } else {
      const userData = {
        email: email,
        password: password,
        onboarding_invite_token: token,
        user_id: userId,
        tenant_id: tenantId,
      };

      axios
        .post(`${BASE_API_URL}/auth/member/complete-sign-up`, userData)
        .then((res) => {
          setToast({
            open: true,
            message: "Account setup complete. Redirecting for login.",
            severity: "success",
          });
          navigate("/welcome/account-creation-success");
        })
        .catch((error) => {
          setToast({
            open: true,
            message: "Failed to complete account setup. Please try again.",
            severity: "error",
          });
        });
    }
  };

  return (
    <React.Fragment>
      <Stack spacing={3}>
        <TextField name="email" label="" value={email} disabled={true} />

        <TextField
          name="password"
          label="Password*"
          type={showPassword ? "text" : "password"}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <CustomIconComponent
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="confirmPassword"
          label="Confirm Password*"
          type={showConfirmPassword ? "text" : "password"}
          onChange={onChange}
          helperText="Password must be at least 8 characters long and contain one uppercase and one lowercase letter and a special character."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  <CustomIconComponent
                    icon={
                      showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <LoadingButton
        sx={{ mt: 4 }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={onSubmit}
        disabled={!validatePassword()}
      >
        Sign Up
      </LoadingButton>
      <Typography variant="body2" sx={{ mb: 5, mt: 2 }}>
        Complete setting up your account in order to use eDispatched. If you
        didn't receive an invitation to create an account, kindly contact your
        club administrator.
      </Typography>
    </React.Fragment>
  );
}

export default MemberAccountSetupComponent;
