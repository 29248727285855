// Details section

// Default
import { useEffect, useState } from "react";

// MUI components
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

// Custom
import FleetDetailCardComponent from "./FleetDetailCardComponent";
import CustomButton from "../../../forms/button/CustomButton";

// Redux
import { useSelector } from "react-redux";

// Context
import { useModal } from "../../../context/ModalContext";

// Modal
import DetailsSectionModal from "./detailModal/DetailsSectionModal";
import { formatCurrency } from "../../../utils/Helper";

const DetailsSectionComponent = () => {
  const [details, setDetails] = useState({});
  const fleet = useSelector((state) => state.fleet);
  const { openModal } = useModal();

  const CATEGORY_OPTIONS = [
    { label: "ASEL", value: "airplane_single_engine_land" },
    { label: "AMEL", value: "airplane_multi_engine_land" },
  ];

  useEffect(() => {
    const details = {
      tail_number: fleet?.details?.tail_number,
      cruise_speed_kts: fleet?.details?.cruise_speed_kts,
      year: fleet?.details?.year,
      make: fleet?.details?.make,
      model: fleet?.details?.model,
      passenger_seats: fleet?.details?.passenger_seats,
      total_seats: fleet?.details?.total_seats,
      category: fleet?.details?.category,
      icao_equipment: fleet?.details?.icao_equipment,
      icao_surveillance_codes: fleet?.details?.icao_surveillance_codes,
      serial_number: fleet?.details?.serial_number,
      faa_designator: fleet?.details?.faa_designator,
      fuel_burn_rate: fleet?.details?.fuel_burn_rate,
      empty_weight: fleet?.details?.empty_weight,
      fuel_capacity: fleet?.details?.fuel_capacity,
      hourly_rate: fleet?.details?.hourly_rate / 100,
    };
    setDetails(details);
  }, [fleet]);

  return (
    <>
      <FleetDetailCardComponent
        title="Details"
        action={
          <CustomButton
            label={"Edit"}
            size={"medium"}
            disabled={false}
            bgColor={"#479DE1"}
            onClick={() => openModal("Details")}
          />
        }
        component={
          <Grid container spacing={{ xs: 5, md: 3 }} columns={{ md: 12 }}>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Cruise Speed (kts)</Typography>
                <Typography paragraph>
                  {details.cruise_speed_kts
                    ? `${details.cruise_speed_kts} kts`
                    : "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Year</Typography>
                <Typography paragraph>{details.year || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Make</Typography>
                <Typography paragraph>{details.make || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Model</Typography>
                <Typography paragraph>{details.model || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Passenger Seats</Typography>
                <Typography paragraph>
                  {details.passenger_seats || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Total Seats</Typography>
                <Typography paragraph>{details.total_seats || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Category</Typography>
                <Typography paragraph>
                  {CATEGORY_OPTIONS?.find(
                    (item) => item?.value === details?.category
                  )?.label || "-"}
                  {/* {details.category || "-"} */}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Icao Equipment</Typography>
                <Typography paragraph>
                  {details.icao_equipment || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">
                  Icao Surveillance Codes
                </Typography>
                <Typography paragraph>
                  {details.icao_surveillance_codes || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Serial Number</Typography>
                <Typography paragraph>
                  {details.serial_number || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Faa Designator</Typography>
                <Typography paragraph>
                  {details.faa_designator || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Fuel Burn Rate</Typography>
                <Typography paragraph>
                  {details.fuel_burn_rate || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Empty Weight</Typography>
                <Typography paragraph>{details.empty_weight || "-"}</Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Fuel Capacity</Typography>
                <Typography paragraph>
                  {details.fuel_capacity || "-"}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <Typography variant="subtitle1">Hourly Rate</Typography>
                <Typography paragraph>
                  {details.hourly_rate
                    ? `$${details.hourly_rate.toFixed(2)}`
                    : "-"}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        }
      />
      <DetailsSectionModal details={details} setDetails={setDetails} />
    </>
  );
};

export default DetailsSectionComponent;
