import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import CustomButton from "../../../forms/button/CustomButton";
import { useModal } from "../../../context/ModalContext";
import { useSelector } from "react-redux";
import FleetDetailCardComponent from "./FleetDetailCardComponent";
import EngineTwoDetailModalComponent from "./detailModal/EngineTwoDetailModalComponent";
import AddEngineTwoNewLineItemModal from "./detailModal/AddEngineTwoNewLineItemModal";

const EngineTwoCardComponent = () => {
  const { openModal, isModal } = useModal();
  const [engineTwoInspections, setEngineTwoInspections] = useState([]);
  const [modalSection, setModalSection] = useState("");
  const [sectionData, setSectionData] = useState({});
  const fleet = useSelector((state) => state.fleet);

  useEffect(() => {
    const engineTwoInspectionsObj =
      fleet?.inspections?.[0]?.engine_two_inspections;

    if (engineTwoInspectionsObj) {
      const inspections = Object.entries(engineTwoInspectionsObj).map(
        ([key, value]) => ({
          title: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase()),
          make: value?.make || "-",
          model: value?.model || "-",
          serial_number: value?.serial_number || "-",
          part_number: value?.part_number || "-",
          next_due_hobbs: value?.next_due_hobbs || "-",
          next_due_tach: value?.next_due_tach || "-",
          next_due_date: value?.next_due_date || "-",
          section: key,
        })
      );
      setEngineTwoInspections(inspections);
    }
  }, [fleet]);

  const handleEditClick = (section) => {
    setModalSection(section);
    setSectionData(
      fleet?.inspections?.[0]?.engine_two_inspections?.[section] || {}
    );
    openModal(
      `EngineTwo${section
        .replace(/_/g, "")
        .replace(/\b\w/g, (l) => l.toUpperCase())}`
    );
  };

  const handleAddLineItemClick = () => {
    openModal("AddEngineTwoNewLineItemModal"); // Open modal for adding a new line item
  };

  const addAction = (
    <Button
      variant="contained"
      onClick={handleAddLineItemClick}
      sx={{ backgroundColor: "#479DE1", color: "#fff" }}
    >
      Add Line Item
    </Button>
  );

  return (
    <>
      <FleetDetailCardComponent
        title="Engine Two Inspections"
        action={addAction}
        component={
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="center">Make</TableCell>
                  <TableCell align="center">Model</TableCell>
                  <TableCell align="center">Serial Number</TableCell>
                  <TableCell align="center">Part Number</TableCell>
                  <TableCell align="center">Next Due Hobbs</TableCell>
                  <TableCell align="center">Next Due Tach</TableCell>
                  <TableCell align="center">Next Due Date</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {engineTwoInspections.length > 0 ? (
                  engineTwoInspections.map((inspection, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {inspection.title}
                        </Typography>
                      </TableCell>

                      <TableCell align="center">{inspection.make}</TableCell>
                      <TableCell align="center">{inspection.model}</TableCell>
                      <TableCell align="center">
                        {inspection.serial_number}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.part_number}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.next_due_hobbs}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.next_due_tach}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.next_due_date}
                      </TableCell>
                      <TableCell align="center">
                        <CustomButton
                          label="Edit"
                          size="small"
                          onClick={() => handleEditClick(inspection.section)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
      />
      {modalSection && (
        <EngineTwoDetailModalComponent
          section={modalSection}
          sectionData={sectionData}
          setSectionData={setSectionData}
        />
      )}
      {isModal.type === "AddEngineTwoNewLineItemModal" && (
        <AddEngineTwoNewLineItemModal />
      )}
    </>
  );
};

export default EngineTwoCardComponent;
