import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookings: {
    upcomingBookings: [],
    pastBookings: [],
  },
};

const bookings = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    resetBookings: (state) => {
      state.bookings = initialState.bookings;
    },
    updateBookingCustomer: (state, action) => {
      const { updatedCustomer, bookingId } = action.payload;

      const updateBookingCustomerDetails = (booking) => {
        if (booking.customer.customer_id === updatedCustomer.customer_id) {
          booking.customer = updatedCustomer;
        }
      };

      const upcomingIndex = state.bookings.upcomingBookings.findIndex(
        (booking) => booking.booking_id === bookingId
      );

      if (upcomingIndex !== -1) {
        updateBookingCustomerDetails(
          state.bookings.upcomingBookings[upcomingIndex]
        );
      } else {
        const pastIndex = state.bookings.pastBookings.findIndex(
          (booking) => booking.booking_id === bookingId
        );
        if (pastIndex !== -1) {
          updateBookingCustomerDetails(state.bookings.pastBookings[pastIndex]);
        }
      }
    },
  },
});

export const { setBookings, resetBookings, updateBookingCustomer } =
  bookings.actions;
export default bookings.reducer;
