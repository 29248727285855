import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "../../../../context/ModalContext";
import { useLoader } from "../../../../context/LoaderContext";
import { useToast } from "../../../../context/ToastContext";
import { fetchPUTRequest } from "../../../../utils/Services";
import { useDispatch, useSelector } from "react-redux";
import { updateEngineTwoInspections } from "../../../../store/features/FleetSlice";

const AddEngineTwoNewLineItemModal = () => {
  const { closeModal } = useModal();
  const { startLoading, stopLoading } = useLoader();
  const { setToast } = useToast();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const fleet = useSelector((state) => state.fleet);

  const [title, setTitle] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [partNumber, setPartNumber] = useState("");
  const [overhaulDueHours, setOverhaulDueHours] = useState("");
  const [overhaulDueDate, setOverhaulDueDate] = useState("");
  const [compliedDate, setCompliedDate] = useState("");
  const [compliedAttHours, setCompliedAttHours] = useState("");
  const [timeSinceLastOverhaul, setTimeSinceLastOverhaul] = useState("");
  const [nextDueHobbs, setNextDueHobbs] = useState("");
  const [nextDueTach, setNextDueTach] = useState("");
  const [intervalHobbs, setIntervalHobbs] = useState("");
  const [intervalTach, setIntervalTach] = useState("");
  const [intervalMonths, setIntervalMonths] = useState("");
  const [nextDueAttHours, setNextDueAttHours] = useState("");
  const [nextDueDate, setNextDueDate] = useState("");

  const validateAndSubmit = async () => {
    if (!title) {
      setToast({
        open: true,
        message: "Title is required.",
        severity: "error",
      });
      return;
    }

    const payload = {
      engine_two_inspections: {
        [title]: {
          make,
          model,
          serial_number: serialNumber,
          part_number: partNumber,
          overhaul_due_hours: overhaulDueHours,
          overhaul_due_date: overhaulDueDate,
          complied_date: compliedDate,
          complied_att_hours: compliedAttHours,
          time_since_last_overhaul: timeSinceLastOverhaul,
          next_due_hobbs: nextDueHobbs,
          next_due_tach: nextDueTach,
          interval_hobbs: intervalHobbs,
          interval_tach: intervalTach,
          interval_months: intervalMonths,
          next_due_att_hours: nextDueAttHours,
          next_due_date: nextDueDate,
        },
      },
      tail_number: fleet.tail_number,
    };

    let apiUrl = "/fleet/member/update-plane-inspections";
    if (user?.role === "owner") {
      apiUrl = "/fleet/owner/update-plane-inspections";
    } else if (user?.role === "admin") {
      apiUrl = "/fleet/admin/update-plane-inspections";
    }

    startLoading();
    const response = await fetchPUTRequest(apiUrl, payload);

    if (response?.statusCode === 200) {
      dispatch(
        updateEngineTwoInspections({
          [title]: payload.engine_two_inspections[title],
        })
      );
      setToast({
        open: true,
        message: "Line item added successfully.",
        severity: "success",
      });
      closeModal();
    } else {
      setToast({
        open: true,
        message: response?.Message || "Failed to add line item.",
        severity: "error",
      });
    }
    stopLoading();
  };

  return (
    <Dialog open={true} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add New Line Item
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <TextField
          margin="dense"
          label="Make"
          type="text"
          fullWidth
          variant="outlined"
          value={make}
          onChange={(e) => setMake(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Model"
          type="text"
          fullWidth
          variant="outlined"
          value={model}
          onChange={(e) => setModel(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Serial Number"
          type="text"
          fullWidth
          variant="outlined"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Part Number"
          type="text"
          fullWidth
          variant="outlined"
          value={partNumber}
          onChange={(e) => setPartNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Overhaul Due Hours"
          type="text"
          fullWidth
          variant="outlined"
          value={overhaulDueHours}
          onChange={(e) => setOverhaulDueHours(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Overhaul Due Date"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={overhaulDueDate}
          onChange={(e) => setOverhaulDueDate(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Complied Date"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={compliedDate}
          onChange={(e) => setCompliedDate(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Complied Att Hours"
          type="text"
          fullWidth
          variant="outlined"
          value={compliedAttHours}
          onChange={(e) => setCompliedAttHours(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Time Since Last Overhaul"
          type="text"
          fullWidth
          variant="outlined"
          value={timeSinceLastOverhaul}
          onChange={(e) => setTimeSinceLastOverhaul(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Next Due Hobbs"
          type="text"
          fullWidth
          variant="outlined"
          value={nextDueHobbs}
          onChange={(e) => setNextDueHobbs(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Next Due Tach"
          type="text"
          fullWidth
          variant="outlined"
          value={nextDueTach}
          onChange={(e) => setNextDueTach(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Interval Hobbs"
          type="text"
          fullWidth
          variant="outlined"
          value={intervalHobbs}
          onChange={(e) => setIntervalHobbs(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Interval Tach"
          type="text"
          fullWidth
          variant="outlined"
          value={intervalTach}
          onChange={(e) => setIntervalTach(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Interval Months"
          type="text"
          fullWidth
          variant="outlined"
          value={intervalMonths}
          onChange={(e) => setIntervalMonths(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Next Due Att Hours"
          type="text"
          fullWidth
          variant="outlined"
          value={nextDueAttHours}
          onChange={(e) => setNextDueAttHours(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Next Due Date"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={nextDueDate}
          onChange={(e) => setNextDueDate(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="contained" color="primary">
          Cancel
        </Button>
        <Button onClick={validateAndSubmit} color="primary" disabled={!title}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEngineTwoNewLineItemModal;
