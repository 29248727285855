import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";

import ImageModal from "../imageModal/ImageModal";
import SpinnerComponent from "../spinner/SpinnerComponent";
import { jpegDownloadRequest, fetchPUTRequest } from "../../utils/Services";
import { useToast } from "../../context/ToastContext";
import EditCustomerModal from "./EditCustomerModal";
import BookingCardComponent from "./BookingCardComponent";
import { formatDate } from "../../utils/Helper";
import CustomButton from "../../forms/button/CustomButton";
import { useSelector } from "react-redux";

const BookingCustomerComponent = () => {
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [modifiedData, setModifiedData] = useState({});
  const { toast, setToast } = useToast();

  const bookingId = location?.state?.booking_id;

  const user = useSelector((state) => state.auth);

  // Find the booking in both upcoming and past bookings using Redux state
  const booking = useSelector((state) => {
    const { upcomingBookings, pastBookings } = state.bookings?.bookings || {};
    const allBookings = [...(upcomingBookings || []), ...(pastBookings || [])];
    return allBookings.find((b) => b.booking_id === bookingId);
  });

  useEffect(() => {
    if (booking && booking.customer) {
      setCustomerData(booking.customer);
    }
  }, [booking]);

  const viewImage = async () => {
    setIsLoading(true);

    let apiUrl = `/document/member/customer/download-customer-document/${customerData?.customer_id}/passport`;

    if (user?.role === "owner") {
      apiUrl = `/document/owner/customer/download-customer-document/${customerData?.customer_id}/passport`;
    } else if (user?.role === "admin") {
      apiUrl = `/document/admin/customer/download-customer-document/${customerData?.customer_id}/passport`;
    }

    const response = await jpegDownloadRequest(apiUrl, {});
    if (response) {
      const file = new Blob([response], { type: "image/jpeg" });
      const fileURL = URL.createObjectURL(file);
      setImageUrl(fileURL);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const downloadImage = async () => {
    setIsLoading(true);
    let apiUrl = `/document/member/customer/download-customer-document/${customerData?.customer_id}/passport`;

    if (user?.role === "owner") {
      apiUrl = `/document/owner/customer/download-customer-document/${customerData?.customer_id}/passport`;
    } else if (user?.role === "admin") {
      apiUrl = `/document/admin/customer/download-customer-document/${customerData?.customer_id}/passport`;
    }

    const response = await jpegDownloadRequest(apiUrl, {});

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "image.jpg");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleUpdate = async () => {
    try {
      let apiUrl = `/member/customer/edit-customer-details`;
      if (user?.role === "admin") {
        apiUrl = `/admin/customer/edit-customer-details`;
      } else if (user?.role === "owner") {
        apiUrl = `/owner/customer/edit-customer-details`;
      }

      const response = await fetchPUTRequest(apiUrl, modifiedData);

      if (response.statusCode === 200) {
        setToast({
          open: true,
          message: "Customer details updated successfully.",
          severity: "success",
        });
      } else {
        setToast({ open: true, message: response.message, severity: "error" });
      }
    } catch (error) {
      setToast({
        open: true,
        message: "An error occurred. Please try again.",
        severity: "error",
      });
    }
    handleEditClose();
  };

  if (customerData === null) {
    return <SpinnerComponent show={true} size={30} />;
  }

  return (
    <>
      <BookingCardComponent
        title={"Booking Customer"}
        action={
          <CustomButton
            label={"Edit Customer"}
            size={"medium"}
            disabled={false}
            bgColor={"#479DE1"}
            onClick={handleEditOpen}
          />
        }
        component={
          <>
            <Grid
              container
              spacing={{ xs: 5, md: 3 }}
              columns={{ md: 12 }}
              style={{ position: "relative" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <SpinnerComponent show={isLoading} size={30} />
              </Box>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  First Name
                </Typography>
                <Typography paragraph align="center">
                  {customerData.first_name || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Last Name
                </Typography>
                <Typography paragraph align="center">
                  {customerData.last_name || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Email
                </Typography>
                <Typography paragraph align="center">
                  {customerData.email || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Phone Number
                </Typography>
                <Typography paragraph align="center">
                  {customerData.phone_number || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Nationality
                </Typography>
                <Typography paragraph align="center">
                  {customerData.nationality || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Date Of Birth
                </Typography>
                <Typography paragraph align="center">
                  {formatDate(customerData.date_of_birth) || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Weight
                </Typography>
                <Typography paragraph align="center">
                  {customerData.weight || "-"} Ibs
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Passport Number
                </Typography>
                <Typography paragraph align="center">
                  {customerData.documents?.passport?.passport_number ||
                    "Not uploaded"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Passport Expiration
                </Typography>
                <Typography paragraph align="center">
                  {formatDate(
                    customerData.documents?.passport?.passport_expiration
                  ) || "Not uploaded"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Passport Date Of Issue
                </Typography>
                <Typography paragraph align="center">
                  {formatDate(
                    customerData.documents?.passport?.date_of_issue
                  ) || "Not uploaded"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  View Passport
                </Typography>
                <Box>
                  {customerData.documents?.passport ? (
                    <>
                      <IconButton
                        color="primary"
                        onClick={viewImage}
                        sx={{ padding: 0 }}
                      >
                        <VisibilityIcon />
                      </IconButton>

                      <IconButton color="primary" onClick={downloadImage}>
                        <DownloadIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Typography paragraph align="center">
                      Not uploaded
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1, mt: 3 }}
              >
                <Typography variant="h6" align="center">
                  Residential Address
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Street
                </Typography>
                <Typography paragraph align="center">
                  {customerData.residential_address?.street || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Unit
                </Typography>
                <Typography paragraph align="center">
                  {customerData.residential_address?.unit || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  City
                </Typography>
                <Typography paragraph align="center">
                  {customerData.residential_address?.city || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  State
                </Typography>
                <Typography paragraph align="center">
                  {customerData.residential_address?.state || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1 }}
              >
                <Typography variant="subtitle1" align="center">
                  Zip Code
                </Typography>
                <Typography paragraph align="center">
                  {customerData.residential_address?.zip_code || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ opacity: isLoading ? 0.5 : 1, mb: 3 }}
              >
                <Typography variant="subtitle1" align="center">
                  Country
                </Typography>
                <Typography paragraph align="center">
                  {customerData.residential_address?.country || "-"}
                </Typography>
              </Grid>
            </Grid>
          </>
        }
      />
      <ImageModal imageUrl={imageUrl} setImageUrl={setImageUrl} />

      <EditCustomerModal
        open={editOpen}
        handleClose={handleEditClose}
        customerData={customerData}
      />
    </>
  );
};

export default BookingCustomerComponent;
