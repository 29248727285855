import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import CustomButton from "../../../forms/button/CustomButton";
import { useModal } from "../../../context/ModalContext";
import { useSelector } from "react-redux";
import FleetDetailCardComponent from "./FleetDetailCardComponent";
import OtherInspectionsModalComponent from "./detailModal/OtherInspectionsModalComponent";
import AddOtherInspectionsNewLineItemModal from "./detailModal/AddOtherInspectionsNewLineItemModal";

const OtherInspectionCardComponent = () => {
  const { openModal, isModal } = useModal();
  const [otherInspections, setOtherInspections] = useState([]);
  const [selectedSectionData, setSelectedSectionData] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const fleet = useSelector((state) => state.fleet);

  useEffect(() => {
    const otherInspectionsObj = fleet?.inspections?.[0]?.other_inspections;

    if (otherInspectionsObj) {
      const inspections = Object.entries(otherInspectionsObj).map(
        ([key, value]) => ({
          title: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (l) => l.toUpperCase())
            .replace("Ifr", "IFR"),
          section: key,
          ...value,
        })
      );
      setOtherInspections(inspections);
    }
  }, [fleet]);

  const handleEditClick = (section) => {
    setSelectedSectionData(
      fleet?.inspections?.[0]?.other_inspections?.[section] || {}
    );
    setSelectedSection(section);
    openModal(
      `OtherInspection${section
        .replace(/_/g, "")
        .replace(/\b\w/g, (l) => l.toUpperCase())}`
    );
  };

  const handleAddLineItemClick = () => {
    openModal("AddOtherInspectionNewLineItemModal"); // Trigger Add New Line Item Modal
  };

  const addAction = (
    <Button
      variant="contained"
      onClick={handleAddLineItemClick}
      sx={{ backgroundColor: "#479DE1", color: "#fff" }}
    >
      Add Line Item
    </Button>
  );

  return (
    <>
      <FleetDetailCardComponent
        title="Other Inspections"
        action={addAction}
        component={
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="center">Calculate By</TableCell>
                  <TableCell align="center">Interval Month</TableCell>
                  <TableCell align="center">Interval Hobbs</TableCell>
                  <TableCell align="center">Interval Tach</TableCell>
                  <TableCell align="center">Due Date</TableCell>
                  <TableCell align="center">Due Hobbs</TableCell>
                  <TableCell align="center">Due Tach</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {otherInspections.length > 0 ? (
                  otherInspections.map((inspection, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {inspection.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {inspection.calculate_by || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.interval_month || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.interval_hobbs || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.interval_tach || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.due_date || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.due_hobbs || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {inspection.due_tach || "-"}
                      </TableCell>
                      <TableCell align="center">
                        <CustomButton
                          label="Edit"
                          size="small"
                          onClick={() => handleEditClick(inspection.section)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
      />
      {selectedSection && (
        <OtherInspectionsModalComponent
          section={selectedSection}
          sectionData={selectedSectionData}
          setSectionData={setSelectedSectionData}
        />
      )}
      {isModal.type === "AddOtherInspectionNewLineItemModal" && (
        <AddOtherInspectionsNewLineItemModal />
      )}
    </>
  );
};

export default OtherInspectionCardComponent;
