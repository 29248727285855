import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tail_number: "",
  details: null,
  maintenanceLogs: [],
  airworthinessDirectives: [],
  inspections: [],
};

const fleet = createSlice({
  name: "fleet",
  initialState,
  reducers: {
    setTailNumber: (state, action) => {
      state.tail_number = action.payload;
    },
    setFleetDetails: (state, action) => {
      state.details = action.payload;
    },
    setMaintenanceLogs: (state, action) => {
      state.maintenanceLogs = action.payload;
    },
    setAirworthinessDirectives: (state, action) => {
      state.airworthinessDirectives = action.payload;
    },
    setInspections: (state, action) => {
      state.inspections = action.payload;
    },
    updateEngineOneInspections: (state, action) => {
      const { payload } = action;
      state.inspections[0].engine_one_inspections = {
        ...state.inspections[0].engine_one_inspections,
        ...payload,
      };
    },
    updateEngineTwoInspections: (state, action) => {
      const { payload } = action;
      state.inspections[0].engine_two_inspections = {
        ...state.inspections[0].engine_two_inspections,
        ...payload,
      };
    },
    updatePropellerOneInspections: (state, action) => {
      const { payload } = action;
      state.inspections[0].propeller_one_inspections = {
        ...state.inspections[0].propeller_one_inspections,
        ...payload,
      };
    },
    updatePropellerTwoInspections: (state, action) => {
      const { payload } = action;
      state.inspections[0].propeller_two_inspections = {
        ...state.inspections[0].propeller_two_inspections,
        ...payload,
      };
    },
    updateOtherInspections: (state, action) => {
      const { payload } = action;
      state.inspections[0].other_inspections = {
        ...state.inspections[0].other_inspections,
        ...payload,
      };
    },
    resetFleet: (state) => {
      state.tail_number = initialState.tail_number;
      state.details = initialState.details;
      state.maintenanceLogs = initialState.maintenanceLogs;
      state.airworthinessDirectives = initialState.airworthinessDirectives;
      state.inspections = initialState.inspections;
    },
  },
});

export const {
  setTailNumber,
  setFleetDetails,
  setMaintenanceLogs,
  setAirworthinessDirectives,
  setInspections,
  updateEngineOneInspections,
  updateEngineTwoInspections,
  updatePropellerOneInspections,
  updatePropellerTwoInspections,
  updateOtherInspections,
  resetFleet,
} = fleet.actions;
export default fleet.reducer;
