// Maintenance logs slice
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maintenanceLogs: []
};

const maintenanceLogs = createSlice({
  name: "maintenanceLogs",
  initialState,
  reducers: {
    setMaintenanceLogs: (state, action) => {
      state.maintenanceLogs = action.payload;
    },
  },
});

export const { setMaintenanceLogs } = maintenanceLogs.actions;
export default maintenanceLogs.reducer;